/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import VendorTable from '../../Tables/VendorTable';
import { ProductsGet } from '../../redux/actions/productAction';
import { VendorProductAll, VendorProductDelete, VendorProductSave, VendorProductUpdate } from '../../redux/actions/vendorAction';
import ConfirmationPopup from '../commen/ConfirmationPopup';

const VendorProducts = ({ vendorList, fetchData }: any) => {
    const usersignin = useSelector((state: any) => state.userSignin);
    const token = usersignin?.userInfo?.token;
    const dispatch = useDispatch()
    const [productList, setProductList] = useState(null as any)
    const [initialProducts, setInitialProducts] = useState(null as any)
    const [prod_id, setProdid] = useState(null as any);
    const [vendorId, setVendorId] = useState(null as any);
    const [selectProduct, setSelectProduct] = useState(null as any);
    const [VendorProduct, setVendorProduct] = useState(null as any);
    const [vendorProductList, setVendorProductList] = useState(null as any)

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            vendorName: "",
            gstType: "",
            prod_Name: "",
            hsn: "",
            price: "",
            purchasePrice: "",
            qty: 1,
            gst: "",
            GstAmount: "",
            CgstPercentage: "",
            CgstAmount: "",
            SgstPercentage: "",
            SgstAmount: "",
            igstTax: "",
            igstAmount: "",
            vendor_id: "",
            user_id: token?._id,
        },
    });


    const fetchData1 = () => {
        dispatch(ProductsGet(token?._id) as any).then((res: any) => {
            if (res) {
                const vendorDetails = res?.payload.filter((item: any) => {
                    return (item.status == true && item)
                })
                setInitialProducts(vendorDetails)
                setProductList(vendorDetails);

            }
        });
    };


    useEffect(() => {
        fetchData1()
        fetchData2()
    }, [])

    const fetchData2 = () => {
        dispatch(VendorProductAll(token?._id) as any).then((res: any) => {
            if (res) {
                setVendorProductList(res.payload);

            }
        });
    };

    const handleProducts = (id: any) => {
        let newArray = [...vendorProductList]
        let VendorProducts = newArray?.filter((vendor: any) => vendor.vendor_id === id)
        if (VendorProducts) {
            let vendorProducts = initialProducts?.filter((product: any) => !VendorProducts?.some((item: any) => item.prod_Name === product.product_name))
            setProductList(vendorProducts);
        } else {
            setProductList(initialProducts);
        }

    }


    const vendor = (e: any) => {
        let defaultValues = {
            gstType: "",
            prod_Name: "",
            hsn: "",
            price: "",
            purchasePrice: "",
            qty: 1,
            gst: "",
            GstAmount: "",
            CgstPercentage: "",
            CgstAmount: "",
            SgstPercentage: "",
            SgstAmount: "",
            igstTax: "",
            igstAmount: "",
            vendor_id: "",
            user_id: token?._id,
        }
        reset({ ...defaultValues });
        setVendorProduct(false)
        let vendorid = vendorList?.find((x: any) => x.vendor_name === e)?._id?.$oid
        setVendorId(vendorid)
        handleProducts(vendorid)
    }

    const purchaseValue = (e: any) => {
        let gstType: any = getValues(`gstType`);
        let purchasePrice: any = getValues(`purchasePrice`);
        let gst: any = getValues(`gst`);
        let CgstPercentage: any = getValues(`CgstPercentage`);
        let SgstPercentage: any = getValues(`SgstPercentage`);
        let igstTax: any = getValues(`igstTax`);
        let gstAmount: any = gst / 100 * purchasePrice
        let cgstAmount: any = CgstPercentage / 100 * purchasePrice
        let sgstAmount: any = SgstPercentage / 100 * purchasePrice
        let igstAmount: any = igstTax / 100 * purchasePrice
        setValue(`GstAmount`, gstAmount);
        setValue(`CgstAmount`, cgstAmount);
        setValue(`SgstAmount`, sgstAmount)
        setValue(`igstAmount`, igstAmount)
        if (gstType === "GST") {
            setValue(`GstAmount`, (gstAmount).toFixed(2));
            setValue(`CgstAmount`, (cgstAmount).toFixed(2));
            setValue(`SgstAmount`, (sgstAmount).toFixed(2));
            setValue(`igstAmount`, "");
        }
        if (gstType === "IGST") {
            setValue(`igstAmount`, (igstAmount).toFixed(2))
            setValue(`GstAmount`, "");
            setValue(`CgstAmount`, "");
            setValue(`SgstAmount`, "");
        }
    }


    const gstType = (e: any) => {
        let total: any = getValues(`gstType`);
        let productName: any = getValues(`prod_Name`);
        const product = productList.find((x: any) => x._id.$oid === productName);
        if (total === "GST") {
            setValue(`gst`, product?.gst)
            let purchasePrice: any = getValues(`purchasePrice`);
            setValue(`CgstPercentage`, product?.CgstPercentage)
            setValue(`SgstPercentage`, product?.SgstPercentage)
            let gst: any = getValues(`gst`);
            let CgstPercentage: any = getValues(`CgstPercentage`);
            let SgstPercentage: any = getValues(`SgstPercentage`);
            let GstAmount: any = gst / 100 * purchasePrice
            let CgstAmount: any = CgstPercentage / 100 * purchasePrice
            let SgstAmount: any = SgstPercentage / 100 * purchasePrice
            setValue(`GstAmount`, GstAmount ? (GstAmount).toFixed(2) : "")
            setValue(`CgstAmount`, CgstAmount ? (CgstAmount).toFixed(2) : "")
            setValue(`SgstAmount`, SgstAmount ? (SgstAmount).toFixed(2) : "")
            setValue(`igstTax`, "")
            setValue(`igstAmount`, "")
        }

        if (total === "IGST") {
            setValue(`igstTax`, product?.igstTax)
            let igstTax: any = getValues(`igstTax`);
            let purchasePrice: any = getValues(`purchasePrice`);
            let igstAmount: any = igstTax / 100 * purchasePrice
            setValue(`igstAmount`, igstAmount ? (igstAmount).toFixed(2) : "")
            setValue(`gst`, "")
            setValue(`CgstPercentage`, "")
            setValue(`SgstPercentage`, "")
            setValue(`GstAmount`, "");
            setValue(`CgstAmount`, "");
            setValue(`SgstAmount`, "")
        }


    }

    const products = (e: any) => {
        //   const productName = (productList?.find((x: any) => x._id.$oid === data.prod_Name)?.product_name)
        let total: any = getValues(`gstType`);
        const product = productList.find((x: any) => x._id.$oid === e);
        if (total === "GST") {
            let purchasePrice: any = getValues(`purchasePrice`);
            setValue(`gst`, product?.gst)
            setValue(`CgstPercentage`, product?.CgstPercentage)
            setValue(`SgstPercentage`, product?.SgstPercentage)
            let gst: any = getValues(`gst`);
            let CgstPercentage: any = getValues(`CgstPercentage`);
            let SgstPercentage: any = getValues(`SgstPercentage`);
            let gstAmount: any = gst / 100 * purchasePrice
            let cgstAmount: any = CgstPercentage / 100 * purchasePrice
            let sgstAmount: any = SgstPercentage / 100 * purchasePrice
            setValue(`GstAmount`, (gstAmount).toFixed(2));
            setValue(`CgstAmount`, (cgstAmount).toFixed(2));
            setValue(`SgstAmount`, (sgstAmount).toFixed(2));


        }
        if (total === "IGST") {
            let purchasePrice: any = getValues(`purchasePrice`);
            setValue(`igstTax`, product?.igstTax)
            let igstTax: any = getValues(`igstTax`);
            let igst: any = igstTax / 100 * purchasePrice
            setValue(`igstAmount`, (igst).toFixed(2))
        }
        const productName = (productList?.find((x: any) => x._id.$oid === e)?.product_name)

        setValue(`hsn`, product?.hsn)
        setValue(`price`, product?.price)
        setSelectProduct(productName)
    }
    const handleCancelClick = () => {

        let defaultValues = {
            vendorName: "",
            gstType: "",
            prod_Name: "",
            hsn: "",
            price: "",
            purchasePrice: "",
            qty: 1,
            gst: "",
            GstAmount: "",
            CgstPercentage: "",
            CgstAmount: "",
            SgstPercentage: "",
            SgstAmount: "",
            igstTax: "",
            igstAmount: "",
            vendor_id: "",
            user_id: token?._id,
        }
        reset({ ...defaultValues });
        setVendorProduct(false)
        setProductList(initialProducts);
        setProdid(null);
        // setVendorId("")

    }

    const onSubmit = (data: any) => {
        let value = {
            vendorName: data.vendorName,
            gstType: data.gstType,
            prod_Name: !prod_id ? selectProduct : data.prod_Name,
            hsn: data.hsn,
            price: data.price,
            purchasePrice: data.purchasePrice,
            qty: data.qty,
            gst: data.gst ? data.gst : 0,
            GstAmount: data.GstAmount ? data.GstAmount : 0,
            CgstPercentage: data.CgstPercentage ? data.CgstPercentage : 0,
            CgstAmount: data.CgstAmount ? data.CgstAmount : 0,
            SgstPercentage: data.SgstPercentage ? data.SgstPercentage : 0,
            SgstAmount: data.SgstAmount ? data.SgstAmount : 0,
            igstTax: data.igstTax ? data.igstTax : 0,
            igstAmount: data.igstAmount ? data.igstAmount : 0,
            vendor_id: vendorId,
            user_id: token?._id,
        };
        if (VendorProduct) {
            dispatch(VendorProductUpdate(prod_id, value) as any).then((res: any) => {
                if (res) {

                    fetchData2();
                    toast.success("Update Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    handleCancelClick();
                    setVendorProduct(false)
                    setSelectProduct(null)
                    setProdid(null)

                }
            });
        } else {
            dispatch(VendorProductSave(value) as any).then((res: any) => {
                if (res) {
                    fetchData2();
                    toast.success("Saved Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    handleCancelClick();
                    setSelectProduct(null)
                }
            });
        }
    };


    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const handleDeleteSection = (data: any) => {
        setShowConfirmation(true);
        setProdid(data?._id?.$oid);

    };
    const handleConfirm = () => {
        dispatch(VendorProductDelete(prod_id) as any).then((res: any) => {
            if (res) {
                fetchData2()
                setProdid(null);
                setShowConfirmation(false);
            }
        });


    };
    const handleCancel = () => {
        setShowConfirmation(false);

    };
    const handleEditSection = (data: any) => {
        setProdid(data?._id?.$oid);
        setVendorProduct(true);
        setVendorId(data.vendor_id)
        let value = {
            vendorName: data.vendorName,
            gstType: data.gstType,
            prod_Name: data.prod_Name,
            hsn: data.hsn,
            price: data.price,
            purchasePrice: data.purchasePrice,
            qty: data.qty,
            gst: data.gst,
            GstAmount: data.GstAmount,
            CgstPercentage: data.CgstPercentage,
            CgstAmount: data.CgstAmount,
            SgstPercentage: data.SgstPercentage,
            SgstAmount: data.SgstAmount,
            igstTax: data.igstTax,
            igstAmount: data.igstAmount,
            vendor_id: data.vendor_id,
        };
        reset({
            ...value,
        });

        // Refilter products when editing, excluding the currently selected product
        // let vendorid = vendorList?.find((x: any) => x.vendor_name === data.vendorName)?._id?.$oid;
        // let VendorProducts = vendorProductList?.filter((vendor: any) => vendor.vendor_id === vendorid && vendor._id.$oid !== data._id.$oid);
        // let vendorProducts = initialProducts?.filter(
        //     (product: any) => !VendorProducts?.some((item: any) => item.prod_Name === product.product_name)
        // );
        // setProductList(vendorProducts);
    };



    return (
        <div>
            <div>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <fieldset className="mx-2 mb-4 rounded-lg p-3 h-auto">
                            <legend className="p-4 font-bold text-[#045545] text-lg ml-2">
                                Vendor Product Details
                            </legend>
                            <div className="rounded-xl py-3 mb-3 bg-[#E1E8E7]">
                                <div className="flex w-full flex-col sm:flex-row px-4">
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold required" style={{ fontFamily: "poppins" }}>
                                                <span className='md:whitespace-nowrap'>Vendor Name</span>
                                            </label>
                                            <div className="relative">
                                                <select
                                                    {...register('vendorName', {
                                                        required: 'Vendor Name is required',
                                                        onChange: (e: any) => vendor(e.target.value),
                                                    })}
                                                    // value={ProductValue}
                                                    disabled={VendorProduct}
                                                    style={{
                                                        fontFamily: "poppins",
                                                        cursor: VendorProduct ? 'not-allowed' : 'default',
                                                    }}
                                                    className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 md:w-full lg:w-40 xl:w-48 2xl:w-56 ${errors.vendorName ? 'border-red-500' : ''}`}
                                                >
                                                    <option value="" disabled hidden>
                                                        Select Vendors
                                                    </option>

                                                    {vendorList.map((item: any) => (
                                                        <option key={item.vendor_name} value={item.vendor_name}>
                                                            {item.vendor_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.vendorName && <p className="text-red-500 text-xs mt-1">{errors.vendorName.message}</p>}
                                        </div>
                                    </div>

                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold required" style={{ fontFamily: "poppins" }}>
                                                <span className='md:whitespace-nowrap'>Select Tax Type</span>
                                            </label>
                                            <div className="relative">
                                                <select
                                                    disabled={VendorProduct}
                                                    {...register('gstType', {
                                                        required: 'Tax Type is required',
                                                        onChange: (e) => gstType(e.target.value),
                                                    })}
                                                    style={{
                                                        fontFamily: "poppins",
                                                        cursor: VendorProduct ? 'not-allowed' : 'default',
                                                    }}
                                                    className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 md:w-full lg:w-40 xl:w-48 2xl:w-56 ${errors.gstType ? 'border-red-500' : ''}`}
                                                >
                                                    <option value="" disabled hidden>
                                                        Select a Types
                                                    </option>
                                                    <option value="GST">GST</option>
                                                    <option value="IGST">IGST</option>
                                                </select>
                                            </div>
                                            {errors.gstType && <p className="text-red-500 text-xs mt-1">{errors.gstType.message}</p>}
                                        </div>
                                    </div>

                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold required" style={{ fontFamily: "poppins" }}>
                                                <span className='md:whitespace-nowrap'>Product Name</span>
                                            </label>
                                            <div className="relative">
                                                <select
                                                    disabled={VendorProduct}
                                                    {...register('prod_Name', {
                                                        required: 'Product Name is required',
                                                        onChange: (e) => products(e.target.value),
                                                    })}
                                                    // value={prod_id ? ProductValue : ""}
                                                    style={{
                                                        fontFamily: "poppins",
                                                        cursor: VendorProduct ? 'not-allowed' : 'default',
                                                    }}
                                                    className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 md:w-full lg:w-40 xl:w-48 2xl:w-56 ${errors.prod_Name ? 'border-red-500' : ''}`}

                                                >
                                                    <option value="" disabled hidden>
                                                        Select Product
                                                    </option>
                                                    {productList?.map((item: any) => (
                                                        <option key={item._id.$oid}
                                                            // value={item.product_name}
                                                            value={prod_id ? item.product_name : item._id.$oid}
                                                        >
                                                            {item.product_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.prod_Name && <p className="text-red-500 text-xs mt-1">{errors.prod_Name.message}</p>}
                                        </div>
                                    </div>

                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                <span>HSN/SAC</span>
                                            </label>
                                            <div className="relative">
                                                <input
                                                    type="number"
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    placeholder="HSN/SAC NO"
                                                    disabled
                                                    {...register('hsn')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex w-full flex-col sm:flex-row px-4">
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                <span>MRP</span>
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    type="text"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    placeholder="MRP"
                                                    disabled
                                                    {...register('price')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold required" style={{ fontFamily: "poppins" }}>
                                                <span className='whitespace-nowrap'>Purchase Price</span>
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    type="text"
                                                    className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 md:w-full lg:w-40 xl:w-48 2xl:w-56 ${errors.purchasePrice ? 'border-red-500' : ''}`}
                                                    placeholder="Purchase Price"
                                                    {...register('purchasePrice', {
                                                        required: 'Purchase Price is required',
                                                        onChange: (e) => {
                                                            const value = e.target.value;
                                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                                            if (regex.test(value)) {
                                                                e.target.value = value;
                                                                setValue(`purchasePrice`, e.target.value)
                                                                purchaseValue(e)
                                                            } else {
                                                                e.target.value = e.target.value.slice(0, -1);
                                                            }
                                                        }
                                                    })}
                                                />
                                            </div>
                                            {errors.purchasePrice && <p className="text-red-500 text-xs mt-1">{errors.purchasePrice.message}</p>}
                                        </div>
                                    </div>
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                GST
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    placeholder="GST"
                                                    type="text"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    disabled
                                                    {...register('gst')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                GstAmount
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    placeholder="GstAmount"
                                                    type="text"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    disabled
                                                    {...register('GstAmount')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex w-full flex-col sm:flex-row px-4">
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                Cgst%
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    placeholder="CgstPercentage"
                                                    type="text"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    disabled
                                                    {...register('CgstPercentage')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                CgstAmount
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    placeholder="CgstAmount"
                                                    type="text"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    disabled
                                                    {...register('CgstAmount')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                SgstTax%
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    placeholder="SgstPercentage"
                                                    type="text"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    disabled
                                                    {...register('SgstPercentage')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                SgstAmount
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    placeholder="SgstAmount"
                                                    type="text"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    disabled
                                                    {...register('SgstAmount')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex w-full flex-col sm:flex-row px-4">
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                IGST
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    placeholder="igstTax"
                                                    type="text"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    disabled
                                                    {...register('igstTax')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6">
                                            <label className="text-[#008065] text-sm font-semibold" style={{ fontFamily: "poppins" }}>
                                                IGST Amount
                                            </label>
                                            <div className="relative">
                                                <input
                                                    style={{ fontFamily: "poppins" }}
                                                    autoComplete="off"
                                                    placeholder="igstAmount"
                                                    type="text"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-40 xl:w-48 2xl:w-full"
                                                    disabled
                                                    {...register('igstAmount')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6"></div>
                                    </div>
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                        <div className="flex flex-col mb-6"></div>
                                    </div>
                                </div>

                                <div className="flex w-full flex-col sm:flex-row pl-4 mb-2">
                                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  emptydiv">
                                        <div className="flex flex-col mb-4 "></div>
                                    </div>

                                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  emptydiv">                                 <div className="flex flex-col mb-4 "></div>                            </div>                            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                                        <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 goods rounded">
                                            <button
                                                type="button"
                                                className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                                onClick={handleCancelClick}
                                                style={{ fontFamily: "poppins", fontSize: "14px" }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  mt-5 w-full ">
                                        <div className="flex flex-col  w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40  goods  rounded">
                                            <button
                                                type="submit"
                                                className="rounded-xl font-bold text-white items-center bg-[#008065]   text-sm h-8  w-full "
                                                style={{ fontFamily: "poppins", fontSize: "14px" }}
                                                onClick={(e: any) => handleSubmit(e)}
                                            >
                                                {VendorProduct ? "Update" : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                {/* The rest of your component */}
            </div>
            <VendorTable vendorProductList={vendorProductList} handleDeleteSection={handleDeleteSection} handleEditSection={handleEditSection} productList={productList

            } />
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div >

    )
}

export default VendorProducts