/* eslint-disable eqeqeq */
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { IoMdDownload } from "react-icons/io";
import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { currencyGet } from "../../../redux/actions/currencyAction";
import { PurchaseReturnDelete, purchaseReturnFindAll } from "../../../redux/actions/purchaseReturnActions";
import ConfirmationPopup from "../../commen/ConfirmationPopup";
// import { downloadPlugin } from '@react-pdf-viewer/download';
const PurchaseReturnTab = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [deleteid, setDeleteId] = useState([] as any);
    const [purchaseDetails, setPurchaseDetails] = useState([] as any);
    const [purchaseReturn, setPurchaseReturn] = useState([] as any);

    const [pdfUrl, setPdfUrl] = useState("");
    const [isPopupOpen, setPopupOpen] = useState(false);

    // const stockdetails = useSelector((state: any) => state.SalesList);
    // const { saleslist } = stockdetails;
    const usersignin = useSelector((state: any) => state?.userSignin);
    const token = usersignin?.userInfo?.token;

    const fetchData = () => {
        dispatch(purchaseReturnFindAll() as any).then((response: any) => {
            setPurchaseDetails(response?.payload);
            setPurchaseReturn(response?.payload);
        });
    };
    useEffect(() => {

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // *******************sort*********************


    const handleCreateInvoice = () => {
        navigate("/app/purchaseReturnInvoice");
    };

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        const filteredData = purchaseReturn?.filter(
            (value: any) =>
                value?.vendorName?.toLowerCase().includes(query)

        );
        setPurchaseDetails(filteredData);
    };
    const handleEdit = (params: any) => {
        navigate(`/app/purchaseReturnInvoice/${params._id}`);

    };

    const rows = purchaseDetails?.map((item: any, index: any) => ({
        id: uuidv4(),
        sl_No: index + 1,
        ...item,
    }));

    const handleDelete = (params: any) => {

        setShowConfirmation(true);
        setDeleteId(params?._id);

    };

    const handleConfirm = () => {
        dispatch(PurchaseReturnDelete(deleteid) as any).then((res: any) => {
            if (res) {
                fetchData();
                setDeleteId(null);
                setShowConfirmation(false);
            }
        });
    };
    const handleCancel = () => {
        setShowConfirmation(false);
    };
    const handleClose = () => {
        setPopupOpen(false);
        setPdfUrl('')
    };
    const [currencySymbol, setcurrencySymbol] = useState("₹");

    useEffect(() => {

        dispatch(currencyGet(token?._id) as any).then((res: any) => {
            if (res) {
                let data = res.payload.find((value: any) => value.defaultInfo === true);
                if (data?.currencySymbol) {
                    setcurrencySymbol(data.currencySymbol);
                } else {
                    setcurrencySymbol("₹");
                }
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleView = (data: any) => {
        setPopupOpen(true)
        const queryParams = new URLSearchParams();
        queryParams.append("param1", "#000000");
        queryParams.append("param2", "#000000");
        queryParams.append("param3", "#000000");
        queryParams.append("param4", "#000000");
        queryParams.append("param5", data?.colorTemplateType)
        queryParams.append("param6", data?.selectedFont);
        queryParams.append("param7", data?.currencySymbol ? data?.currencySymbol : currencySymbol);
        queryParams.append("param8", data?.invoiceTemplateType ? data?.invoiceTemplateType : data?.colorTemplateType);
        fetch(`/api/purchaseRetrun-view/${data._id}?${queryParams.toString()}`)
            .then((response) => {

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                setPdfUrl(url);

            })
            .catch((error) => console.error("Error downloading PDF:", error))

    };
    const columns: GridColDef[] = [
        {
            field: "invoiceNo",
            headerName: "Invoice No",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.invoiceNo ? params.row.invoiceNo : "N/A"}</>
            ),
        },
        {
            field: "vendorName",
            headerName: "Client Name",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.vendorName ? params.row.vendorName : "N/A"}</>
            ),
        },
        {
            field: "vendorMobile",
            headerName: "Client Mobile",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.vendorMobile ? params.row.vendorMobile : "N/A"}</>
            ),
        },
        {
            field: "vendorEmail",
            headerName: "Client Email",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.vendorEmail ? params.row.vendorEmail : "N/A"}</>
            ),
        },
        {
            field: "purchaseReturnDate",
            headerName: "Date",
            editable: false,
            width: 100,
            renderCell: (params: any) => (
                <>{params.row.purchaseReturnDate ? params.row.purchaseReturnDate : "N/A"}</>
            ),
        },
        {
            field: "toatalAmount",
            headerName: "Total Amount",
            align: "right",
            editable: false,
            width: 120,
            renderCell: (params: any) => (
                <>
                    {params.row.toatalAmount
                        ? Number(params.row.toatalAmount)?.toFixed(2)
                        : "N/A"}
                </>
            ),
        },
        {
            field: "unit_description",
            headerName: "View",
            editable: false,
            width: 100,
            renderCell: (params: any) => (
                <>
                    <IconButton
                        onClick={() => handleView(params.row)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#008065"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                        </svg>
                    </IconButton>
                </>
            ),
        },
        {
            field: "action",
            headerName: "Actions",
            editable: false,
            width: 200,
            renderCell: (params: any) => (
                <>
                    <IconButton aria-label="edit"
                        onClick={() => handleEdit(params.row)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5 cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                        </svg>
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(params.row)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5  cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                        </svg>
                    </IconButton>
                </>
            ),
        },
    ];

    const zoomPluginInstance = zoomPlugin();
    const { ZoomIn, ZoomOut, Zoom } = zoomPluginInstance;

    const printPluginInstance = printPlugin();
    const { PrintButton } = printPluginInstance;

    return (
        <div>
            {isPopupOpen && (
                <>
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                        <div className="w-full  max-w-lg bg-transparent p-4 sm:p-6 lg:p-8 transform scale-[1.05] sm:scale-[1.1]">
                            <div
                                className="pdf-viewer-container rounded-lg shadow-lg overflow-hidden"
                                style={{ backgroundColor: 'white', maxWidth: '100%', maxHeight: '90vh' }}
                            >
                                {pdfUrl && (
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                        <div className="flex justify-end items-center p-4 space-x-4">
                                            <zoomPluginInstance.ZoomIn />
                                            <zoomPluginInstance.ZoomOut />
                                            <zoomPluginInstance.Zoom />
                                            <a href={pdfUrl} download className="btn-download">
                                                <IoMdDownload />
                                            </a>
                                            <printPluginInstance.PrintButton />
                                            <button
                                                className="text-red-500 rounded"
                                                onClick={() => handleClose()}
                                            >
                                                <IoCloseSharp />

                                            </button>
                                        </div>

                                        <div className="h-full max-h-[75vh] overflow-hidden px-4">
                                            <Viewer fileUrl={pdfUrl}
                                                plugins={[zoomPluginInstance, printPluginInstance,]} />
                                        </div>
                                    </Worker>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                <div className="p-4  font-bold text-[#008065]  ml-2">Purchase Return - Report</div>
                <div className="p-4  font-bold text-[#008065]  ml-2">
                    <button
                        type="button"
                        className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                        style={{ backgroundColor: "#008065" }}
                        onClick={handleCreateInvoice}
                    >
                        <FiPlusCircle className="h-5 w-5" />
                        <span className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`} >Add Purchase Return</span>
                    </button></div>


            </div>
            <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 ml-3 w-[86%] md:w-[93%] lg:w-[97%] xl:w-[96.5%] 2xl:w-[96.6%]">
                <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row">
                    <div className="flex items-center">
                        <div className="relative flex mb-2">
                            <input
                                onChange={onSearch}
                                type="text"
                                id="simple-search"
                                className="w-44 bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                                placeholder="Search"
                                required
                            />
                            <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                                <svg
                                    className="w-4 h-4"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>

                </div>
                <Box
                    sx={{
                        height: 400,
                        width: "100%",
                        overflowX: "auto",
                        overflowY: "auto",
                    }}
                >
                    <DataGrid
autoHeight
                        sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: 500,
                            ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                            {
                                backgroundColor: "#fff",
                                color: "#008060 !important",
                                fontWeight: 600,
                                fontSize: "14px",
                            },
                            "& .MuiDataGrid-checkboxInput": {
                                color: "#008060 !important",
                            },
                            ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                fontWeight: 600,
                                fontSize: "14px",
                            },
                            ".MuiDataGrid-cell.MuiDataGrid-cell--textRight": {
                                fontWeight: 600,
                                fontSize: "14px",
                            },
                            "@media (min-width: 2555px)": {
                                ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
                                {
                                    minWidth: "265px !important",
                                },
                            },
                        }}
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25, 50]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

export default PurchaseReturnTab;
