/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ProductTables from "../../Tables/ProductTables";
import {
  Products,
  ProductsDeactive,
  ProductsGet,
  ProductsUpdate
} from "../../redux/actions/productAction";
import { VendorAll } from "../../redux/actions/vendorAction";
import ConfirmationDeactivePopup from "../commen/ConfirmationDeactivePopup";

function ProductPage({ productLists, setProductLists }: any) {
  const dispatch = useDispatch();
  const [productId, setProducId] = useState("prod-0001" as any);
  const [prod_id, setProdid] = useState(null as any);
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;
  const [formData, setFormData] = useState({
    product_id: null,
    product_name: "",
    hsn: "",
    price: "",
    units: "",
    qty: "1",
    gst: "",
    subtotal: "",
    GstAmount: "",
    CgstPercentage: "",
    CgstAmount: "",
    SgstPercentage: "",
    SgstAmount: "",
    igstTax: "",
    igstAmount: "",
    sellingPrice: "",
    discount: "",
    description: "",
    status: true,
    user_id: token?._id,
  } as any);

  const [errors, setErrors] = useState<Partial<any>>({});

  // ***************************cancel button section*****************************
  const resetProduct = () => {
    setFormData({
      product_id: null,
      product_name: "",
      hsn: "",
      price: "",
      units: "",
      qty: "1",
      gst: "",
      subtotal: "",
      GstAmount: "",
      CgstPercentage: "",
      CgstAmount: "",
      SgstPercentage: "",
      SgstAmount: "",
      igstTax: "",
      igstAmount: "",
      sellingPrice: "",
      discount: "",
      description: "",
      user_id: token?._id,
    } as any);
  };

  const handleCancelClick = () => {
    let obje = {
      product_id: null,
      product_name: null,
      hsn: null,
      price: null,
      units: null,
      qty: null,
      gst: null,
      subtotal: null,
      GstAmount: null,
      CgstPercentage: null,
      CgstAmount: null,
      SgstPercentage: null,
      SgstAmount: null,
      igstTax: null,
      igstAmount: null,
      sellingPrice: null,
      discount: null,
      user_id: null,
      description: null,
    };
    setErrors({ ...obje });
    resetProduct();
    setProdid(null);
  };

  // ***************************Edit Enter Sction*****************************
  const handleEditSection = (data: any) => {

    let obje = {
      product_id: null,
      product_name: null,
      hsn: null,
      price: null,
      units: null,
      qty: null,
      gst: null,
      subtotal: null,
      GstAmount: null,
      CgstPercentage: null,
      CgstAmount: null,
      SgstPercentage: null,
      SgstAmount: null,
      igstTax: null,
      igstAmount: null,
      sellingPrice: null,
      discount: null,
      description: null,
      user_id: null,
    };
    setErrors({ ...obje });
    setFormData({
      product_id: data.product_id,
      product_name: data.product_name,
      hsn: data.hsn,
      price: data.price,
      units: data.units,
      qty: data.qty,
      gst: data.gst,
      subtotal: data.subtotal,
      GstAmount: data.GstAmount,
      CgstPercentage: data.CgstPercentage,
      CgstAmount: data.CgstAmount,
      SgstPercentage: data.SgstPercentage,
      SgstAmount: data.SgstAmount,
      igstTax: data.igstTax,
      igstAmount: data.igstAmount,
      sellingPrice: data.sellingPrice,
      discount: data.discount,
      description: data.description,
      user_id: token?._id,
    } as any);
    setProdid(data?._id?.$oid);
  };

  // ***************************DELETE  Sction*****************************
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  // const handleDeleteSection = (data: any) => {
  //   setShowConfirmation(true);
  //   setProdid(data?._id?.$oid);
  //   resetProduct();
  // };
  const handleDeactiveSection = (data: any) => {

    setShowConfirmation(true);
    setProdid(data?._id?.$oid);
    setFormData({
      product_id: data.product_id,
      product_name: data.product_name,
      hsn: data.hsn,
      price: data.price,
      units: data.units,
      qty: data.qty,
      gst: data.gst,
      subtotal: data.subtotal,
      GstAmount: data.GstAmount,
      CgstPercentage: data.CgstPercentage,
      CgstAmount: data.CgstAmount,
      SgstPercentage: data.SgstPercentage,
      SgstAmount: data.SgstAmount,
      igstTax: data.igstTax,
      igstAmount: data.igstAmount,
      sellingPrice: data.sellingPrice,
      discount: data.discount,
      description: data.description,
      status: data.status,
      user_id: token?._id,
    } as any);
    // resetProduct();
  }



  const handleCancel = () => {
    setShowConfirmation(false);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let newvalue = value.trimStart();
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: newvalue,
    }));
    if (name == "price") {
      let rate = Number(value);
      let Quantity = Number(formData.qty);
      let SubTotal = rate * Quantity;
      formData.subtotal = SubTotal;
      if (name == "price" && formData.gst) {
        let rate = Number(value);
        let Quantity = Number(formData.qty);
        let SubTotal = rate * Quantity;
        formData.subtotal = SubTotal;

        let gstamount = Number(formData.gst);
        let GstTotal = (gstamount * formData.subtotal) / 100;
        formData.GstAmount = GstTotal;

        let SgstPercentage = gstamount / 2;
        let CgstPercentage = gstamount / 2;

        let SgstAmount = (SgstPercentage * formData.subtotal) / 100;
        let CgstAmount = (CgstPercentage * formData.subtotal) / 100;

        let igstamount = Number(formData.igstTax);
        let IGstTotal = (igstamount * formData.subtotal) / 100;
        formData.igstAmount = IGstTotal;

        formData.SgstAmount = SgstAmount;
        formData.CgstAmount = CgstAmount;
        formData.SgstPercentage = SgstPercentage;
        formData.CgstPercentage = CgstPercentage;
      }
    }

    if (name == "gst") {
      let gstamount = Number(value);
      let GstTotal = (gstamount * formData.subtotal) / 100;
      formData.GstAmount = GstTotal;

      let SgstPercentage = gstamount / 2;
      let CgstPercentage = gstamount / 2;

      let SgstAmount = (SgstPercentage * formData.subtotal) / 100;
      let CgstAmount = (CgstPercentage * formData.subtotal) / 100;

      formData.SgstAmount = SgstAmount;
      formData.CgstAmount = CgstAmount;
      formData.SgstPercentage = SgstPercentage;
      formData.CgstPercentage = CgstPercentage;
    }
    if (name == "igstTax") {
      let igstamount = Number(value);
      let IGSTTotal = (igstamount * formData.subtotal) / 100;
      formData.igstAmount = IGSTTotal;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Perform validation
    const validationErrors: Partial<any> = {};

    const Duplicate = productLists.some((product: any) => {
      return (
        product.product_name.trim().toLowerCase() ===
        formData.product_name.trim().toLowerCase() &&
        product._id.$oid !== prod_id
      );
    });

    if (Duplicate) {
      validationErrors.product_name = "Product Name already exists.";
    }

    if (!formData.product_name.trim()) {
      validationErrors.product_name = "Product Name is Required.";
    }
    if (!formData.units.trim()) {
      validationErrors.units = "Units is Required.";
    }
    if (!formData.price.trim()) {
      validationErrors.price = "Price is Required.";
    }
    if (!formData.gst.trim()) {
      validationErrors.gst = "GST is Required.";
    }
    if (!formData.igstTax.trim()) {
      validationErrors.igstTax = "IGST Tax is Required.";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (prod_id) {
        dispatch(ProductsUpdate(prod_id, formData) as any).then((res: any) => {
          if (res) {
            setFormData({
              product_id: null,
              product_name: "",
              hsn: "",
              price: "",
              units: "",
              qty: "1",
              prodImage: "",
              user_id: token?._id,
              gst: "",
              subtotal: "",
              GstAmount: "",
              CgstPercentage: "",
              CgstAmount: "",
              SgstPercentage: "",
              SgstAmount: "",
              igstTax: "",
              igstAmount: "",
              sellingPrice: "",
              discount: "",
              description: "",
            });
            setProdid(null);
            fetchData();
            toast.success("Update SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
          }
        });
        resetProduct();
      } else {
        dispatch(Products(formData) as any).then((res: any) => {
          if (res) {
            setFormData({
              product_id: null,
              product_name: "",
              hsn: "",
              price: "",
              units: "",
              qty: "1",
              prodImage: "",
              user_id: token?._id,
              gst: "",
              subtotal: "",
              GstAmount: "",
              CgstPercentage: "",
              CgstAmount: "",
              SgstPercentage: "",
              SgstAmount: "",
              igstTax: "",
              igstAmount: "",
              sellingPrice: "",
              discount: "",
              description: "",
              status: true
            });

            fetchData();
            setProdid(null);
            toast.success("Saved SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            // resetProduct();
          }
        });
      }
    }
  };

  const handleConfirm = () => {

    dispatch(ProductsDeactive(prod_id, formData) as any).then((res: any) => {
      if (res) {
        fetchData();
        setProdid(null);
        setFormData({
          product_id: "",
          product_name: "",
          hsn: "",
          price: "",
          units: "",
          qty: "",
          gst: "",
          subtotal: "",
          GstAmount: "",
          CgstPercentage: "",
          CgstAmount: "",
          SgstPercentage: "",
          SgstAmount: "",
          igstTax: "",
          igstAmount: "",
          sellingPrice: "",
          discount: "",
          description: "",
          status: "",
          user_id: token?._id,
        } as any);
        setShowConfirmation(false);
      }
    });

    // dispatch(ProductsDelete(prod_id) as any).then((res: any) => {
    //   if (res) {
    //     fetchData();
    //     setProdid(null);
    //     setShowConfirmation(false);
    //   }
    // });
  };

  // **********************************Get Call Section**************************************
  const fetchData = () => {
    dispatch(ProductsGet(token?._id) as any).then((res: any) => {
      if (res) {
        if (res.payload.length > 0) {
          const [name, num] = res.payload[0].product_id.split("-");
          let numeric_part = Number(num);
          let next_numeric_part = numeric_part + 1;
          const paddedIndex = String(next_numeric_part).padStart(4, "0");
          setProducId(`prod-${paddedIndex}`);
        } else {
          const paddedIndex = String(1).padStart(4, "0");
          setProducId(`prod-${paddedIndex}`);
        }
        setProductLists(res.payload);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

 ;
  return (
    <>
      <fieldset className="mx-3 mb-3 rounded-lg">
        <legend className="p-4  font-bold text-[#045545]  text-lg    ml-2">
          Product
        </legend>
        {/* <DownloadExcelSheet />
        <UploadProducts /> */}
        <form>
          <div className="rounded-xl bg-[#E1E8E7]">
            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold required"
                  >
                    <span>Product ID</span>
                  </label>
                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="nope"
                      // value={productForm.productID}
                      className={
                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                      }
                      placeholder="Product ID"
                      readOnly
                      name="product_id"
                      value={
                        formData.product_id ? formData.product_id : productId
                      }
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold required"
                  >
                    <span className='md:whitespace-nowrap'>Product Name</span>
                  </label>
                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 ${errors.product_name
                        ? " border-2  border-[red] w-11/12"
                        : ""
                        }`}
                      placeholder="Product Name"
                      name="product_name"
                      value={formData.product_name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.product_name && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {errors.product_name}
                    </span>
                  )}
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold"
                  >
                    <span>HSN/SAC</span>
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      className={
                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                      }
                      placeholder="HSN/SAC NO"
                      name="hsn"
                      value={formData.hsn}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold required"
                  >
                    <span>MRP</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 ${errors.price ? " border-2  border-[red] w-11/12" : ""
                        }`}
                      placeholder="MRP"
                      name="price"
                      value={formData.price}
                      onChange={(e) => {
                        const { value } = e.target;
                        const regex = /^\d*\.?\d*$/;
                        if (value === "" || regex.test(value)) {
                          handleChange(e);
                          setFormData({ ...formData, price: value });
                        }
                      }}
                    />
                  </div>
                  {errors.price && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {errors.price}
                    </span>
                  )}
                </div>
              </div>
              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold"
                  >
                    <span>Selling Price</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56`}
                      placeholder="Selling Price"
                      name="sellingPrice"
                      value={formData.sellingPrice}
                      onChange={(e) => {
                        const { value } = e.target;
                        const regex = /^\d*\.?\d*$/;
                        if (value === "" || regex.test(value)) {
                          handleChange(e);
                          setFormData({ ...formData, sellingPrice: value });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="text-[#008065]  text-sm font-semibold required"
                  >
                    <span>Units</span>
                  </label>
                  <div className="relative">
                    <select
                      name="units"
                      value={formData.units}
                      onChange={(e: any) => handleChange(e)}
                      style={{ fontFamily: "poppins" }}
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 md:w-28 lg:w-30 xl:w-48 2xl:w-56 ${errors.units ? " border-2  border-[red] w-11/12" : ""
                        }`}
                    >
                      <option value="" disabled  hidden>
                        Select a Types
                      </option>
                      <option value="kg">KG</option>
                      <option value="ltr">Ltr</option>
                      <option value="qty">Qty</option>
                      <option value="others">Others</option>
                    </select>
                  </div>
                  {errors.units && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {errors.units}
                    </span>
                  )}
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold"
                  >
                    Qty
                  </label>
                  <div className="relative">
                    <input
                      disabled
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      placeholder="Quantity"
                      type="number"
                      className={
                        "border-2 input border-[#008065] cursor-not-allowed  focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                      }
                      name="qty"
                      value={formData.qty}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold required"
                  >
                    <span>GST</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 ${errors.gst ? " border-2  border-[red] w-11/12" : ""
                        }`}
                      placeholder="GST"
                      name="gst"
                      value={formData.gst}
                      onChange={(e) => {
                        const { value } = e.target;
                        const regex = /^\d*\.?\d*$/;
                        if (
                          value === "" ||
                          (regex.test(value) && parseFloat(value) <= 100)
                        ) {
                          handleChange(e);
                          setFormData({ ...formData, gst: value });
                        }
                      }}
                    />
                  </div>
                  {errors.gst && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {errors.gst}
                    </span>
                  )}
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold "
                  >
                    <span>GST Amount</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      disabled
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 `}
                      placeholder="GST Amount"
                      name="GstAmount"
                      value={
                        formData.GstAmount
                          ? Number(formData.GstAmount).toFixed(2)
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold "
                  >
                    <span>CGST Tax</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 `}
                      placeholder="CGST Tax"
                      name="CgstPercentage"
                      value={formData.CgstPercentage}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold "
                  >
                    <span>CGST Amount</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 `}
                      placeholder="CGST Amount"
                      name="CgstAmount"
                      value={
                        formData.CgstAmount
                          ? Number(formData.CgstAmount).toFixed(2)
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold "
                  >
                    <span>SGST Tax</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 `}
                      placeholder="SGST Tax"
                      name="SgstPercentage"
                      value={formData.SgstPercentage}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold "
                  >
                    <span>SGST Amount</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 `}
                      placeholder="SGST Amount"
                      name="SgstAmount"
                      value={
                        formData.SgstAmount
                          ? Number(formData.SgstAmount).toFixed(2)
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold required"
                  >
                    <span>IGST Tax</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 ${errors.igstTax ? " border-2  border-[red] w-11/12" : ""
                        }`}
                      placeholder="IGST Tax"
                      name="igstTax"
                      value={formData.igstTax}
                      onChange={(e) => {
                        const { value } = e.target;
                        const regex = /^\d*\.?\d*$/;
                        if (
                          value === "" ||
                          (regex.test(value) && parseFloat(value) <= 100)
                        ) {
                          handleChange(e);
                          setFormData({ ...formData, igstTax: value });
                        }
                      }}
                    />
                  </div>
                  {errors.igstTax && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {errors.igstTax}
                    </span>
                  )}
                </div>
              </div>

              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold "
                  >
                    <span>IGST Amount</span>
                  </label>

                  <div className="relative">
                    <input
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 `}
                      placeholder="IGST Amount"
                      name="igstAmount"
                      value={
                        formData.igstAmount
                          ? Number(formData.igstAmount).toFixed(2)
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col sm:flex-row pl-4 mb-2">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="flex flex-col mb-4">
                <label
                  style={{ fontFamily: "poppins" }}
                  className=" text-[#008065]  text-sm font-semibold "
                >
                  <span>Discount</span>
                </label>

                <div className="relative">
                  <input
                    style={{ fontFamily: "poppins" }}
                    autoComplete="off"
                    type="text"
                    className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 `}
                    placeholder="Discount (Optional)"
                    name="discount"
                    value={formData.discount}
                    onChange={(e) => {
                      const { value } = e.target;
                      const regex = /^\d*\.?\d*$/;
                      if (
                        value === "" ||
                        (regex.test(value) && parseFloat(value) <= 100)
                      ) {
                        handleChange(e);
                        setFormData({ ...formData, discount: value });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col sm:flex-row pl-4 mb-2">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="flex flex-col mb-4 w-[78%]">
                <label
                  style={{ fontFamily: "poppins" }}
                  className=" text-[#008065]  text-sm font-semibold "
                >
                  <span>Description</span>
                </label>
                <div className="relative">
                  <textarea
                    style={{ fontFamily: "poppins" }}
                    autoComplete="off"
                    className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-[100px] w-48 sm:w-full`}
                    placeholder="Description (Optional)"
                    name="description"
                    value={formData.description}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  emptydiv">
              <div className="flex flex-col mb-4 "></div>
            </div>
          </div>
          <div
            className="sm:flex pl-[10px] sm:pr-4"
            style={{ justifyContent: "end", marginBottom: "1rem" }}
          >
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
              <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 goods rounded">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                  onClick={handleCancelClick}
                  style={{ fontFamily: "poppins", fontSize: "14px" }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title px-2  mt-5">
              <div className="flex flex-col  w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40  goods  rounded">
                <button
                  type="submit"
                  className="rounded-xl font-bold text-white items-center bg-[#008065]   text-sm h-8  w-full "
                  style={{ fontFamily: "poppins", fontSize: "14px" }}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  {prod_id ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </fieldset>
      <ProductTables
        productList={productLists}
        handleEditSection={handleEditSection}
        handleDeactiveSection={handleDeactiveSection}
      />
      {showConfirmation && (
        <ConfirmationDeactivePopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
}

export default ProductPage;
