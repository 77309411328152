/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable eqeqeq */
import ClearIcon from "@mui/icons-material/Clear";
import { useRef, useState } from "react";
import SignModel from "../../models/SignModel";

function Signature({
  formData,
  setFormData,
  handleOpen,
  sign,
}: any) {
  const inputRef2 = useRef(null as any);
  const inputRef3 = useRef(null as any);

  const handleSign = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        setFormData({ ...formData, signature: base64Data });
        if (inputRef2.current) {
          inputRef2.current.value = "";
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSeal = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        setFormData({ ...formData, seal: base64Data });
        if (inputRef3.current) {
          inputRef3.current.value = "";
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const ClearSign = (e: any) => {
    e.preventDefault();
    formData.signature = "";
    setFormData({ ...formData });
    inputRef2.current.value = "";
  };

  const ClearSeal = (e: any) => {
    e.preventDefault();
    formData.seal = "";
    setFormData({ ...formData });
    inputRef3.current.value = "";
  };

  const [isModalSigns, setIsModalSigns] = useState(false);
  const handlesigns = () => {
    setIsModalSigns(true);
  };

  const closeModal = () => {
    setIsModalSigns(false);
  };

  const [ExistingData, setExistingData] = useState([] as any);

  const handleSelect = (data: any) => {
    setExistingData(data);
    setFormData({
      ...formData,
      signature: data.signImage,
      seal: data.sealImage,
      otherValue: data.otherValue,
    });
    setIsModalSigns(false);
  };

  return (
    <div className="">
      <div className="flex-col">
        <div className="flex justify-between mt-2">
          <div className="2xl:flex xl:flex lg:flex md:flex sm:block flex justify-between">
            <p className="text-sm font-semibold text-black sm:pl-5">
              Signature and Seal:
            </p>
          </div>

            <div className="block  ">
              <button
                type="button"
                className="mr-6 text-sm font-semibold text-[#008065]"
                onClick={handlesigns}
              >
                Add Existing Signs
              </button>
            </div>
          </div>

        {isModalSigns && (
          <SignModel closeModal={closeModal} handleSelect={handleSelect} />
        )}

        <div className="flex sm:pl-5">
          <div className="p-2.5 w-full sm:w-[100%] text-sm border-2 focus:outline-none rounded border-[#008065] border-solid mt-2">
            <div className="2xl:flex xl:flex lg:flex md:flex sm:block justify-between">
              <div
                className={
                  sign?.signPostion === "R"
                    ? "lg:order-2 xl:order-2 2xl:order-2 md:order-2 flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 w-1/4 2xl:w-1/3 items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 mt-2 hover:bg-gray-100 relative"
                    : "flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 mt-2 hover:bg-gray-100 relative"
                }
              >
                {formData?.signature && (
                  <p className="font-semibold text-[#008065] pt-2">Signature:</p>
                )}
                <div className="relative w-full h-full flex items-center justify-center">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="object-cover pb-2 flex flex-col items-center px-4 cursor-pointer w-full h-full"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputRef2}
                      className="hidden"
                      name="signature"
                      onChange={(e) => handleSign(e)}
                    />
                    {formData?.signature ? (
                      <>
                        <ClearIcon
                          sx={{ position: "absolute", top: "-12px", right: "8px", cursor: "pointer" }}
                          onClick={(e) => ClearSign(e)}
                        />
                        <img
                          className="w-[130px] h-[80px] sm:w-[210px] sm:h-[90px] inline-block shrink-0 pb-2"
                          alt="sign"
                          src={
                            formData?.signature
                              ? formData?.signature
                              : sign?.signature
                          }
                        />
                      </>
                    ) : (
                      <>
                        <svg
                          className="w-8 h-8 mt-6"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <span className="mt-2 text-base leading-normal">
                          Upload a Signature
                        </span>
                      </>
                    )}
                  </label>
                </div>
              </div>
              <div
                className={
                  sign?.sealPostion === "L"
                    ? "lg:order-1 xl:order-1 2xl:order-1 md:order-1 flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/4 2xl:w-1/3 items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 mt-2 hover:bg-gray-100 relative"
                    : "flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 mt-2 hover:bg-gray-100 relative"
                }
              >
                {formData?.seal && (
                  <p className="font-semibold text-[#008065] pt-2">Seal:</p>
                )}
                <div className="relative w-full h-full flex items-center justify-center">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="object-cover pb-2 flex flex-col items-center px-4 cursor-pointer w-full h-full"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputRef3}
                      className="hidden"
                      name="seal"
                      onChange={(e) => handleSeal(e)}
                    />
                    {formData?.seal ? (
                      <>
                        <ClearIcon
                          sx={{ position: "absolute", top: "-12px", right: "8px", cursor: "pointer" }}
                          onClick={(e) => ClearSeal(e)}
                        />
                        <img
                          className="w-[130px] h-[80px] sm:w-[220px] sm:h-[90px] inline-block shrink-0 pb-2"
                          alt="sign"
                          src={formData?.seal ? formData?.seal : sign?.seal}
                        />
                      </>
                    ) : (
                      <>
                        <svg
                          className="w-8 h-8 mt-6"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <span className="mt-2 text-base leading-normal">
                          Upload a Seal
                        </span>
                      </>
                    )}
                  </label>
                </div>
              </div>
            </div>
            {ExistingData.otherValue && (
            
            <div className="flex items-center">
            <h2 className="font-bold text-[#008065] ml-20 mt-4">{ExistingData.otherValue}</h2>
            <h2 className="font-bold text-[#008065] ml-20 mt-2"></h2>
          </div>
          
              )}
            </div>
            <div className="relative ">
              <button onClick={() => handleOpen("addSigntuare")} type="button">
                <svg
                  className="h-6 text-grey"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Signature;
