import React from "react";
import ReactApexChart from "react-apexcharts";

interface Props {
  count: any[]; // Specify the type of count as an array of any
}

const options: any = {
  // Your options object...
};

const ChartsOne: React.FC<Props> = ({ count }) => {
  if (!Array.isArray(count)) {
    // If count is not an array, return a message or handle the error gracefully
    return <div>Error: Data is not iterable.</div>;
  }

  return (
    <div>
      <div className="col-span-12 rounded-lg border border-stroke w-[85.5%] md:w-[93%] lg:w-[97%] xl:w-[94.5%] 2xl:w-[94.7%] ml-3 md:ml-3 lg:ml-3 xl:ml-3 bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
        <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
          <div className="text-2xl">Invoices</div>
          <div className="inline-flex items-center rounded-md bg-whiter p-1.5 dark:bg-meta-4">
            <button className="rounded bg-white py-1 px-3 text-xs font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white dark:hover:bg-boxdark">
              Day
            </button>
            <button className="rounded py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card dark:text-white dark:hover:bg-boxdark">
              Week
            </button>
            <button className="rounded py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card dark:text-white dark:hover:bg-boxdark">
              Month
            </button>
          </div>
        </div>
        <div>
          <div id="chartOne">
            <ReactApexChart
              options={options}
              series={[{ name: "Product One", data: [...count] }]}
              type="area"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MemoizedChartsOne = React.memo(ChartsOne, (prevProps, nextProps) => {
  return prevProps.count === nextProps.count;
});

export default MemoizedChartsOne;
