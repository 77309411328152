import { Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "../pages/commen/ConfirmationPopup";
import { paymentOutDelete, paymentOutGetList } from "../redux/actions/paymentOutAction";
import { useData } from "../layouts/shared/DataProvider";
import { fontFamilyGet } from "../redux/actions/FontFamilyAction";
import { currencyGet } from "../redux/actions/currencyAction";

const PaymentOutTable = () => {
    const [paymentReceiveTable, setPaymentReceiveTable] = useState([] as any);
    const [paymentSearch, setPaymentSearch] = useState([] as any);

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState(null as any);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const { selectedFont, setSelectedFont }: any = useData();
    const [currencySymbol, setcurrencySymbol] = useState("₹");
    const [pdfUrl, setPdfUrl] = useState("");

    const dispatch = useDispatch();
    const paymentreceivegetDetails = useSelector((state: any) => state.paymentReceiveGet);
    const { paymentreceivegetlist } = paymentreceivegetDetails;
    const usersignin = useSelector((state: any) => state.userSignin);
    const { userInfo } = usersignin;
    const userid = userInfo.token._id
    const navigateTo = useNavigate();;

    const handleEdit = (params: any) => {

        navigateTo(`/app/paymentOutPage/${params._id.$oid}`);
    };

    const fetchData = () => {
        dispatch(paymentOutGetList() as any).then((res: any) => {
            if (res) {
                setPaymentReceiveTable(res.payload);
                setPaymentSearch(res.payload)
            }
        });
    };
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        dispatch(fontFamilyGet(userid) as any).then((res: any) => {
            if (res?.type === "FONTFAMILY_GET_SUCCESS") {
                let data = res?.payload?.find(
                    (value: any) => value?.defaultInfo === true,
                );
                if (data) {
                    setSelectedFont(data?.fontfamily);
                } else {
                    setSelectedFont("poppins");
                }
            }
        });
    }, []);
    useEffect(() => {

        dispatch(currencyGet(userid) as any).then((res: any) => {
            if (res) {
                let data = res.payload.find((value: any) => value.defaultInfo === true);
                if (data?.currencySymbol) {
                    setcurrencySymbol(data.currencySymbol);
                } else {
                    setcurrencySymbol("₹");
                }
            }
        });
    }, []);
    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        const filteredData = paymentSearch.filter((paymentOutDetail: any) =>
            paymentOutDetail.vendorName?.toLowerCase().includes(query)
        );
        setPaymentReceiveTable(filteredData);
    };

    const handleDelete = (params: any) => {
        setShowConfirmation(true);
        setDeleteId(params?._id?.$oid);

    };
    const handleConfirm = () => {

        // const record = paymentreceivegetlist.find((item: any) => item._id?.$oid == deleteId);

        // let paymentDeletedata = {
        //     deleteInvoiceNo: record?.invoiceNo,
        //     deleteReceivedAmount: record?.receivedAmount,
        //     deleteBalanceAmount: record?.balanceAmount,
        //     deleteTotalAmount: record?.toatalAmount,
        //     user_id: userid,
        // };

        dispatch(paymentOutDelete(deleteId) as any).then((res: any) => {
            if (res) {
                fetchData();
                setDeleteId(null);
                setShowConfirmation(false);
            }
        });

    };
    const handleCancel = () => {
        setShowConfirmation(false);
    };
    const handleView = (data: any) => {
        setPopupOpen(true)

        const queryParams = new URLSearchParams();

        queryParams.append("param6", selectedFont);
        queryParams.append("param7", currencySymbol);


        fetch(`/api/payment/out-view/${data._id.$oid}/${userid}?${queryParams.toString()}`)
            .then((response) => {

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                setPdfUrl(url);

            })
            .catch((error) => console.error("Error downloading PDF:", error))
    };

    const rows = paymentReceiveTable.map((item: any, index: any) => ({
        id: uuidv4(),
        sno: index + 1,
        ...item,
    }));
    const handleClose = () => {
        setPopupOpen(false);
    };
    const columns: GridColDef[] = [
        { field: "sno", headerName: "S No", width: 120 },
        {
            field: "creation_date",
            headerName: "Date",
            flex: 2,
            minWidth: 190,
            valueFormatter: (params: any) =>
                moment(params.value).format("DD/MM/YYYY"),
        },
        {
            field: "paymentOutReceiptNo",
            headerName: "Receipt No",
            flex: 2,
            minWidth: 190,
        },
        {
            field: "vendorName",
            headerName: "Vendor Name",
            flex: 2,
            minWidth: 190,
            renderCell: (params: any) => (
                <>
                    {params?.row?.transaction_type === 'PURCHASE RETURN' ?
                        <p>
                            {params.row.vendorName} (Vendor)
                        </p>
                        :
                        <p>
                            {params.row.vendorName}
                        </p>
                    }
                </>
            )
        },
        {
            field: "invoiceNo",
            headerName: "Invoice No",
            flex: 2,
            minWidth: 190,
        },
        {
            field: "paymentType",
            headerName: "PAYMENT TYPE",
            flex: 2,
            minWidth: 190,
        },
        {
            field: "transaction_type",
            headerName: "Transaction Type",
            flex: 2,
            minWidth: 190,
        },
        {
            field: "toatalAmount",
            headerName: "Total Amount",
            flex: 2,
            minWidth: 190,
            renderCell: (params: any) => (
                <div style={{ textAlign: "right", width: "55%" }}>
                    {params?.row?.transaction_type === "PURCHASE" ?
                        <p>
                            {parseFloat(params.row.toatalAmount).toFixed(2)}
                        </p>
                        :
                        <p>
                            {parseFloat(params.row.receivedAmount).toFixed(2)}
                        </p>
                    }
                </div>
            ),
        },
        {
            field: "receivedAmount",
            headerName: "Payable Amount",
            flex: 2,
            minWidth: 190,
            renderCell: (params: any) => (
                <div style={{ textAlign: "right", width: "60%" }}>
                    {params?.row?.transaction_type === "PURCHASE" ?
                        <p>
                            {parseFloat(params.row.cashAmount ||
                                params.row.chequeAmount ||
                                params.row.creditAmount).toFixed(2)}
                        </p>
                        :
                        <p>
                            {parseFloat(params.row.receivedAmount).toFixed(2)}
                        </p>
                    }
                </div>
            ),
        },
        {
            field: "balanceAmount",
            headerName: "Balance Amount",
            flex: 2,
            minWidth: 190,
            renderCell: (params: any) => (
                <div style={{ textAlign: "right", width: "60%" }}>
                    {params?.row?.transaction_type === "PURCHASE" ?
                        <p>
                            {parseFloat(params.row.balanceAmount).toFixed(2)}
                        </p>
                        :
                        <p>
                            {(0).toFixed(2)}
                        </p>
                    }
                </div>
            ),
        },
        {
            field: "unit_description",
            headerName: "View",
            editable: false,
            width: 100,
            renderCell: (params: any) => (
                <>
                    <Tooltip title="View">
                        <IconButton
                        onClick={() => handleView(params.row)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg "
                                className="h-5 w-5 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="#008065"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                            </svg>
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            editable: false,
            flex: 2,
            minWidth: 150,
            renderCell: (params: any) => (
                <>
                    <IconButton aria-label="edit" onClick={() => handleEdit(params?.row)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5 cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                        </svg>
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={() => {
                            handleDelete(params.row);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5  cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                        </svg>
                    </IconButton>
                </>
            ),
        },
    ];
    return (
        <div>
            {isPopupOpen && (
                <>
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-4 rounded shadow-lg w-full max-w-md">

                            <div>
                                <iframe src={pdfUrl} title="/" width="100%" height="600px" style={{ border: 'none', background: "white" }}></iframe>
                                <div className="flex flex-row-reverse mt-3">
                                    <button
                                        type="button"
                                        className=" w-full py-1 inline-flex justify-end rounded-md border border-gray-300 shadow-sm px-4  bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )}
            <div>
                <div className="bg-[#F1F7F6] mt-[10px] rounded-xl px-3 py-3 mx-2">
                    <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                        <div className="flex items-center gap-2">
                            <div className="relative flex  mb-2">
                                <input
                                    type="text"
                                    id="simple-search"
                                    className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                                    placeholder="Search"
                                    required
                                    onChange={(e) => onSearch(e)}
                                />
                                <span
                                    className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]"
                                >
                                    <svg
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div className="bg-[#F1F7F6]" style={{ borderRadius: "8px" }}>
                        <Box sx={{ height: "84vh", width: "100%" }}>
                            <DataGrid
autoHeight
                                sx={{
                                    ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                                    {
                                        color: "rgb(0, 128, 101)",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                    },
                                    ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                        fontWeight: 600,
                                        fontSize: "14px",
                                    },
                                    "& .MuiDataGrid-checkboxInput": {
                                        color: "#008060 !important",
                                    },
                                }}
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 25, 50]}

                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    )
}

export default PaymentOutTable
