import { QUOTATION_SERVICEINVOICE_DELETE_FAIL, QUOTATION_SERVICEINVOICE_DELETE_REQUEST, QUOTATION_SERVICEINVOICE_DELETE_SUCCESS, QUOTATION_SERVICEINVOICE_FIND_ALL_FAIL, QUOTATION_SERVICEINVOICE_FIND_ALL_REQUEST, QUOTATION_SERVICEINVOICE_FIND_ALL_SUCCESS, QUOTATION_SERVICEINVOICE_FIND_ONE_FAIL, QUOTATION_SERVICEINVOICE_FIND_ONE_REQUEST, QUOTATION_SERVICEINVOICE_FIND_ONE_SUCCESS, QUOTATION_SERVICEINVOICE_POST_FAIL, QUOTATION_SERVICEINVOICE_POST_REQUEST, QUOTATION_SERVICEINVOICE_POST_SUCCESS } from "../constants/quotationServiceConstants";
import api from "../axiosConfig";
export const QuotationServicePost =
   (externalInvoice: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: QUOTATION_SERVICEINVOICE_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();

         const { data } = await api.post(
            `/api/service/quotation/save`,
            externalInvoice,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: QUOTATION_SERVICEINVOICE_POST_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: QUOTATION_SERVICEINVOICE_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

   export const quotationServiceFindAll = () => async (dispatch: any, getState: any) => {
      dispatch({ type: QUOTATION_SERVICEINVOICE_FIND_ALL_REQUEST });
   
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.get(
            `/api/quotationservice/find-all/${token?._id}`,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: QUOTATION_SERVICEINVOICE_FIND_ALL_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: QUOTATION_SERVICEINVOICE_FIND_ALL_FAIL,          
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };


   export const quotationServiceFindOne =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: QUOTATION_SERVICEINVOICE_FIND_ONE_REQUEST});
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.get(`/api/quotationservice/find-one/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: QUOTATION_SERVICEINVOICE_FIND_ONE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: QUOTATION_SERVICEINVOICE_FIND_ONE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };


   export const quotationserviceDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: QUOTATION_SERVICEINVOICE_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/quotationInvoice/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: QUOTATION_SERVICEINVOICE_DELETE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: QUOTATION_SERVICEINVOICE_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };