/* eslint-disable react-hooks/rules-of-hooks */
import { createBrowserRouter } from "react-router-dom";
import AuthenticatedRoutes from "./Auth/AuthenticatedRoutes";
import PaymentOutTab from "./Tables/PaymentOutTab";
import PaymentReceiveTab from "./Tables/PaymentReceiveTab";
import PurchaseTableNew from "./Tables/PurchaseTableNew";
import SalesReturnTab from "./Tables/SalesReturnTab";
import SalesTabe from "./Tables/SalesTabe";
import Stock from "./Tables/Stock";
import Container from "./container/Container";
import ChangePassword from "./pages/Authentication/ChangePassword";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import LoginPage from "./pages/Authentication/LoginPage";
import MailVerification from "./pages/Authentication/MailVerification";
import OtpScreenPage from "./pages/Authentication/OtpScreenPage";
import RegisterPage from "./pages/Authentication/RegisterPage";
import VerifyCodePage from "./pages/Authentication/VerifyCodePage";
import ProformaInvoice from "./pages/Invoices/ProformaInvoice";
import ServiceTab from "./pages/Service/ServiceTab";
import AdminDashboard from "./pages/SuperAdmin/AdminDashboard";
import AdminPayments from "./pages/SuperAdmin/AdminPayments";
import ExternalInvoice from "./pages/SuperAdmin/ExternalInvoice";
import Unit from "./pages/SuperAdmin/Unit";
import User from "./pages/SuperAdmin/User";
import Unauthorized from "./pages/commen/Unauthorized";
import AppSettingPage from "./pages/components/AppSettingPage";
import ClientPage from "./pages/components/ClientPage";
import CompanyPage from "./pages/components/CompanyPage";
import DashboardHome from "./pages/components/DashboardHome";
import DeliveryChallanPage from "./pages/components/DeliveryChallanPage";
import FeedbackPage from "./pages/components/FeedbackPage";
import HomePage from "./pages/components/HomePage";
import ProductTab from "./pages/components/InvoiceDetails/ProductTab";
import VendorTab from "./pages/components/InvoiceDetails/VendorTab";
import InvoicePage from "./pages/components/InvoicePage";
import NotificationPage from "./pages/components/NotificationPage";
import PaymentOutPage from "./pages/components/PaymentOutPage";
import PaymentReceivePage from "./pages/components/PaymentReceivePage";
import ProtectedRoute from "./pages/components/ProtectedRoute";
import PurchaseReturnTab from "./pages/components/PurchaseDetails/PurchaseReturnTab";
import PurchasePage from "./pages/components/PurchasePage";
import PurchaseReturn from "./pages/components/PurchaseReturn";
import Quotation from "./pages/components/Quotation";
import SalesReturn from "./pages/components/SalesReturn";
import ServiceSettingsPage from "./pages/components/ServiceSettingsPage";
import SignaturePage from "./pages/components/SignaturePage";
import UserDetails from "./pages/components/UserDetails";
import UserSettingsTab from "./pages/components/UserSettingsTab";
import QuotationTableReport from "./Tables/QuotationTableReport";
import DeliveryChallanTable from "./Tables/DeliveryChallanTable";
import ExpenseCategory from "./pages/components/ExpenseCategory";
import ExpensesPage from "./pages/components/ExpensesPage";
import ExpensePageTable from "./Tables/ExpensePageTable";
import Blankinvoice from "./pages/Invoices/Blankinvoice";
import Consultinginvoice from "./pages/Invoices/Consultinginvoice";
import Autoserviceinvoice from "./pages/Invoices/Autoserviceinvoice";
import Billfreeinvoice from "./pages/Invoices/Billfreeinvoice";
import Commericalinvoice from "./pages/Invoices/Commercialinvoice";
import Medicalinvoice from "./pages/Invoices/Medicalinvoice";
import Photographyinvoice from "./pages/Invoices/Photographyinvoice";
import Graphicdesigninvoice from "./pages/Invoices/Graphicdesigninvoice";
import Hourlyrateinvoice from "./pages/Invoices/Hourlyrateinvoice";
import Gstinvoice from "./pages/Invoices/Gstinvoice";
import Billgenerator from "./pages/Invoices/Billgenerator";
import Contractorinvoice from "./pages/Invoices/Contractorinvoice";
import Creditnotesinvoice from "./pages/Invoices/Creditnotesinvoice";
import Deliverynoteinvoice from "./pages/Invoices/Deliverynoteinvoice";
import Einvoice from "./pages/Invoices/Einvoice";
import Electricalshopinvoice from "./pages/Invoices/Electricalshopsinvoice";
import Gymfitnessinvoice from "./pages/Invoices/Gymfitnessinvoice";
import Invoicegenerator from "./pages/Invoices/Invoicegenerator";
import Paymentvoucherinvoice from "./pages/Invoices/Paymentvoucherinvoice";
import Quotationestimateinvoice from "./pages/Invoices/Quotationestimateinvoice";
import Receiptvoucherinvoice from "./pages/Invoices/Receiptvoucherinvoice";
import Refundvoucherinvoice from "./pages/Invoices/Refundvoucherinvoice";
import Salesinvoice from "./pages/Invoices/Salesinvoice";
import Servicesinvoice from "./pages/Invoices/Serviceinvoice";
import Soletraderinvoice from "./pages/Invoices/Soletraderinvoice";
import Taxinvoice from "./pages/Invoices/Taxinvoice";
import Teachinginvoice from "./pages/Invoices/Teachinginvoice";
import Textilesinvoice from "./pages/Invoices/Textilesinvoice";
import Timesheetinvoice from "./pages/Invoices/Timesheetinvoice";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Container />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/:id",
        element: <HomePage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/register",
        element: <RegisterPage />,
      },
      {
        path: "/forgetPassword",
        element: <ForgetPassword />,
      },
      {
        path: "/changePassword",
        element: <ChangePassword />,
      },
      {
        path: "/OtpScreenPage",
        element: <OtpScreenPage />,
      },
      {
        path: "/registration/:id",
        element: <MailVerification />,
      },
      {
        path: "/verify",
        element: <VerifyCodePage />,
      },
      {
        path: "/free-proforma-invoice.html",
        element: <ProformaInvoice />,
      },
      {
        path: "/free-blank-invoice.html",
        element: <Blankinvoice />,
      },
      {
        path: "/free-consulting-invoice.html",
        element: <Consultinginvoice />,
      },
      {
        path: "/free-autoservice-invoice.html",
        element: <Autoserviceinvoice />,
      },
      {
        path: "/free-billfree-invoice.html",
        element: <Billfreeinvoice />,
      },
      {
        path: "/free-commerical-invoice.html",
        element: <Commericalinvoice />,
      },
      {
        path: "/free-medical-invoice.html",
        element: <Medicalinvoice />,
      },
      {
        path: "/free-photography-invoice.html",
        element: <Photographyinvoice />,
      },
      {
        path: "/free-graphicdesign-invoice.html",
        element: <Graphicdesigninvoice />,
      },
      {
        path: "free-hourlyrate-invoice.html",
        element: <Hourlyrateinvoice />,
      },
      {
        path: "/free-gst-invoicer-invoice.html",
        element: <Gstinvoice />,
      },
      {
        path: "/free-bill-generator-invoice.html",
        element: <Billgenerator />,
      },
      {
        path: "/free-contractor-invoice.html",
        element: <Contractorinvoice />,
      },
      {
        path: "/free-credit-notes-invoice.html",
        element: <Creditnotesinvoice />,
      },
      {
        path: "/free-delivery-note-invoice.html",
        element: <Deliverynoteinvoice />,
      },
      {
        path: "/free-delivery-challan-note.html",
        element: <DeliveryChallanPage />,
      },
     
      {
        path: "/free-e-invoice.html",

        element: <Einvoice />,
      },
      {
        path: "/free-gst-invoice.html",
        element: <Electricalshopinvoice />,
      },
      {
        path: "/free-gym-fitness-invoice.html",
        element: <Gymfitnessinvoice />,
      },
      {
        path: "/free-hourly-rate-invoice.html",
        element: <Hourlyrateinvoice />,
      },
      {
        path: "/free-gst-invoice.html",
        element: <Invoicegenerator />,
      },
      {
        path: "/free-payment-voucher-invoice.html",
        element: <Paymentvoucherinvoice />,
      },
      {
        path: "/free-gst-invoice.html",
        element: <Quotationestimateinvoice />,
      },
      {
        path: "/free-receipt-voucher-invoice.html",
        element: <Receiptvoucherinvoice />,
      },
      {
        path: "/free-refund-voucher-invoice.html",
        element: <Refundvoucherinvoice />,
      },
      {
        path: "/free-sales-invoice.html",
        element: <Salesinvoice />,
      },
      {
        path: "/free-services-invoice.html",
        element: <Servicesinvoice />,
      },
      {
        path: "/free-sole-trader-invoice.html",
        element: <Soletraderinvoice />,
      },
      {
        path: "/free-tax-invoice.html",
        element: <Taxinvoice />,
      },
      {
        path: "/free-teaching-invoice.html",
        element: <Teachinginvoice />,
      },
      {
        path: "/free-textiles-invoice.html",
        element: <Textilesinvoice />,
      },
      {
        path: "/free-timesheet-invoice.html",
        element: <Timesheetinvoice />,
      },
    ],
  },
  {
    path: "/app",
    element: <AuthenticatedRoutes />,
    children: [
      {
        path: "home",
        element: <DashboardHome />,
      },
      {
        path: "client",
        element: <ClientPage />,
      },
      {
        path: "vendors",
        element: <VendorTab />,
      },
      {
        path: "invoice",
        element: <InvoicePage />,
      },
      {
        path: "invoice/:id",
        element: <InvoicePage />,
      },
      {
        path: "product",
        element: <ProductTab />,
      },
      {
        path: "company",
        element: <CompanyPage />,
      },
      {
        path: "purchase",
        element: <PurchaseTableNew />,
      },
      {
        path: "purchaseReturn",
        element: <PurchaseReturnTab />,
      },
      {
        path: "purchaseReturnInvoice",
        element: <PurchaseReturn />,
      },
      {
        path: "purchaseReturnInvoice/:id",
        element: <PurchaseReturn />,
      },
      {
        path: "purchasePage",
        element: <PurchasePage />,
      },
      {
        path: "purchasePage/:id",
        element: <PurchasePage />,
      },
      {
        path: "sales",
        element: <SalesTabe />,
      },
      {
        path: "salesReturnTab",
        element: <SalesReturnTab />,
      },
      {
        path: "salesreturn",
        element: <SalesReturn />,
      },
      {
        path: "salesreturn/:id",
        element: <SalesReturn />,
      },
      {
        path: "quotationPage",
        element: <Quotation />,
      },
      {
        path: "quotationPage/:id",
        element: <Quotation />,
      },
      {
        path: "deliveryChallan",
        element: <DeliveryChallanPage />,
      },
      {
        path: "deliveryChallan/:id",
        element: <DeliveryChallanPage />,
      },
      {
        path:"deliveryChallanTab",
        element: <DeliveryChallanTable />,
      },
      {
        path: "stock",
        element: <Stock />,
      },
      {
        path: "payments",
        element: <PaymentReceiveTab />,
      },
      {
        path: "paymentsReceive",
        element: <PaymentReceivePage />,
      },
      {
        path: "paymentsReceive/:id",
        element: <PaymentReceivePage />,
      },
      {
        path: "service",
        element: <ServiceTab />,
      },
      {
        path: "expenseCategeroy",
        element: <ExpenseCategory />,
      },
      {
        path: "expensePage",
        element: <ExpensesPage />,
      },
      {
        path: "expensePage/:id",
        element: <ExpensesPage />,
      },
      {
        path: "expensePageTable",
        element: <ExpensePageTable />,
      },
      {
        path: "serviceSettings",
        element: <ServiceSettingsPage />,
      },
      {
        path: "signature",
        element: <SignaturePage />,
      },
      {
        path: "appSetting",
        element: <AppSettingPage />,
      },
      {
        path: "paymentsOut",
        element: <PaymentOutTab />,
      },
      {
        path: "paymentOutPage",
        element: <PaymentOutPage />,
      },
      {
        path: "paymentOutPage/:id",
        element: <PaymentOutPage />,
      },
      {
        path: "feedback",
        // element: <FeedbackPage />,
        element: (
          <ProtectedRoute
            path="feedback"
            element={<FeedbackPage />}
            requiredRole="Admin"
          />
        ),
      },
      {
        path: "quotation",
        element: <QuotationTableReport/>
      },
      {
        path: "notification",
        element: <NotificationPage />,
      },
      {
        path: "Users",
        element: <User />,
      },
      {
        path: "AdminPayment",
        element: <AdminPayments />,
      },
      {
        path: "ExternalInvoice",
        element: <ExternalInvoice />,
      },
      {
        path: "AdminDashboard",
        element: <AdminDashboard />,
      },
      {
        path: "Units",
        element: <Unit />,
      },
      {
        path: "adduser",
        element: <UserDetails />,
      },
      {
        path: "unauthorized",
        element: <Unauthorized />,
      },
      {
        path: "usersetting",
        element: <UserSettingsTab />,
      },
    ],
  },
]);
export default routes;
