/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerAll, updateUser } from '../../redux/actions/userAction';

const InvoiceTypes = () => {
    const [selectedImageTypes, setSelectedImageTypes] = useState(null as any);
    const [popupImage, setPopupImage] = useState(null as any);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [defaultInfoChecked, setDefaultInfoChecked] = useState<boolean>(false);
    const [selectPopupValue, setSelectePopupValue] = useState({ src: '', default: false });

    const [selectPopupValue1, setSelectePopupValue1] = useState({ src: '', default: false });
    const [status, setstatus] = useState(false);
    //   const template = useSelector((state: any) => state.userColorTemplate);
    //   const { userdata } = template;

    const [popupstatus, setpopupstatus] = useState(false);
    const [popupnewstatus, setpopupnewstatus] = useState(false);



    const template = useSelector((state: any) => state.userColorTemplate);
    const { userdata } = template;


    const images = [
        { src: "/templateTypesImages/invoice-type-service.jpg", alt: "invoice-type-service", default: false },
        { src: "/templateTypesImages/invoice-type-01.webp", alt: "invoice-type-01", default: false },
        { src: "/templateTypesImages/invoice-type-02.webp", alt: "invoice-type-02", default: false },
        { src: "/templateTypesImages/invoice-type-03.webp", alt: "invoice-type-03", default: false },
        { src: "/templateTypesImages/invoice-type-04.webp", alt: "invoice-type-04", default: false },
        { src: "/templateTypesImages/invoice-type-05.webp", alt: "invoice-type-05", default: false },
        { src: "/templateTypesImages/invoice-type-06.webp", alt: "invoice-type-06", default: false },
        { src: "/templateTypesImages/invoice-type-07.webp", alt: "invoice-type-07", default: false },
        { src: "/templateTypesImages/invoice-type-08.webp", alt: "invoice-type-08", default: false },
        { src: "/templateTypesImages/invoice-type-09.webp", alt: "invoice-type-09", default: false },

    ];

    const User_id = sessionStorage.getItem("userInfo");
    const userId = JSON.parse(User_id as any);
    const dispatch = useDispatch()

    const fetchData = () => {
        dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
            // eslint-disable-next-line eqeqeq
            if (res && res.type == "REGISTER_GET_SUCCESS") {
                setSelectedImageTypes(res?.payload?.invoiceType && res?.payload?.invoiceType);
                setSelectePopupValue((prev) => ({
                    src: res?.payload?.invoiceType && res?.payload?.invoiceType,
                    default: res?.payload?.checked
                }));
            }
        })
    };

    useEffect(() => {
        if (userId?.token?._id) {
            fetchData();
        }
    }, []);

    const handleTemplateTypes = (image: any) => {
        setShowConfirmation(true);
        if (userdata.taxType === 'Product Wise' && image?.alt == "invoice-type-01") {
            setpopupstatus(true);
            setShowConfirmation(false);
        }
        else if (userdata.taxType === 'Bill Wise' && image?.alt != "invoice-type-01") {
            setpopupnewstatus(true)
            setShowConfirmation(false);
        }
        setSelectedImageTypes(image?.alt)
        setPopupImage(image.src)
    }


    const handleSubmit = (e: any) => {
        setstatus(true);

        if (defaultInfoChecked) {
            e.preventDefault();
            let obj = {
                invoiceType: selectPopupValue1?.default ? selectedImageTypes : "",
                checked: selectPopupValue1?.default,
                template: ""
            }
            dispatch(updateUser(userId?.token?._id, obj) as any).then((res: any) => {
                if (res && res.type === "USER_UPDATE_SUCCESS ") {
                    fetchData();
                    setShowConfirmation(false);
                    setDefaultInfoChecked(false)
                }
            })
        }

    };

    const handleCancel = () => {
        setShowConfirmation(false);
        setDefaultInfoChecked(false);
        setstatus(false);
        setSelectePopupValue1({ src: '', default: false });
    };

    const handleChecked = (e: any, data: any) => {
        const { checked } = e.target
        setSelectePopupValue1((prev) => ({
            src: selectedImageTypes,
            default: checked,
        }))
        // setSelectePopupValue(popupImage)
        setDefaultInfoChecked(checked)

    }

    const handlePopupCancel = () => {
        setpopupstatus(false);
        setShowConfirmation(false);
    }
    const handlePopupnewCancel = () => {
        setpopupnewstatus(false);
        setShowConfirmation(false);
    }

    return (
        <>
            <div className='overflow-y-scroll lg:h-[30rem] md:h-[30rem] xl:h-[30rem]'>
                <div className="flex flex-wrap">
                    {images.map((image, index) => (
                        <div key={index} className='py-2 cursor-pointer image-selector image-container w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 justify-center relative'>
                            <img
                                src={image.src}
                                alt={image.alt}
                                className='w-40'
                                onClick={() => handleTemplateTypes(image)}
                                style={{
                                    border: selectedImageTypes === image.alt ? '2px solid #008065' : 'none',
                                    cursor: 'pointer',
                                }}
                            />
                            {selectPopupValue?.src === image.alt && (
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-[#008065] border-2 border-white">
                                        <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M8.293 13.707a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-1.414-1.414L9 11.586 6.707 9.293a1 1 0 0 0-1.414 1.414l3 3z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                </div>
                            )}

                            {showConfirmation && selectedImageTypes === image.alt && (
                                <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>
                                    <div className="fixed inset-0 z-10 flex items-center justify-center w-screen overflow-y-auto">
                                        <div className="h-screen min-h-screen flex items-center justify-center p-4 text-center sm:p-0">
                                            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-[75%] 2xl:w-[90%] w-[90%] max-w-lg h-auto border border-gray-200">
                                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                    <div className="flex justify-center">
                                                        <img src={popupImage} alt="Descriptive Alt Text" className="max-w-full h-auto" />
                                                    </div>
                                                </div>
                                                <div className="bg-gray-50 px-4 py-3 flex justify-between sm:px-6">
                                                    <div className='flex  mt-[1.3rem] md:mt-[0.8rem] lg:mt-[0.8rem] xl:mt-[0.8rem] 2xl:mt-[0.8rem]'>
                                                        <input
                                                            type="checkbox"
                                                            checked={(image.alt === selectPopupValue1?.src ? selectPopupValue1?.default : false || image.alt === selectPopupValue?.src ? selectPopupValue?.default : false)}
                                                            onChange={(e) => handleChecked(e, image)}
                                                            className="border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                            }}
                                                        />
                                                        <span className='ml-2 text-sm font-semibold text-gray-900'> Select</span>
                                                    </div>
                                                    <div className='flex flex-row-reverse '>
                                                        <button onClick={(e: any) => handleSubmit(e)} type="button" className="ml-2 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Submit</button>
                                                        <button onClick={handleCancel} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Close</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {popupstatus &&
                (
                    <div className="font-sans justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-35">
                        <div className="md:text-sm 2xl:text-lg relative lg-[39rem] my-6 mx-auto  md:w-[28rem] w-[16rem]">
                            <div className="md:text-sm 2xl:text-lg border-solid border-2 border-red-700  rounded-md shadow-lg h-[11rem] bg-[#e1e8e7] relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                                <div className="items-start justify-between p-2 border-solid border-slate-200 rounded-t bg-red-700">
                                    <div
                                        className="flex p-1 rounded-b justify-between  "
                                    >
                                        <h2 className="md:text-xl 2xl:text-xl  font-semibold text-white text-xl">Alert</h2>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="17"
                                            height="17"
                                            viewBox="0 0 384 512"
                                            fill="#fff"
                                            className="mt-1.5 cursor-pointer"
                                            onClick={handlePopupCancel}

                                        >
                                            <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                                        </svg>
                                    </div>
                                </div>
                                <p className="md:text-sm 2xl:text-lg xl:text-lg lg:text-lg md:text-lg font-semibold justify-center text-sm items-center text-center mt-2">
                                    <p>{"Please choose Tax settings Tab as Bill wise"}</p>
                                </p>
                                <div className="flex p-2 border-solid border-slate-200 rounded-b md:justify-end sm:justify-end  mt-2">
                                    <div className="lg:flex lg:justify-end flex justify-end">

                                        <button
                                            className="md:text-xs 2xl:text-lg xl:text-lg lg:text-lg md:text-lg bg-red-700 text-white font-medium items-right justify-end mr-2 rounded-lg px-2 py-1 text-xs inline-flex "
                                            type="button"
                                            onClick={handlePopupCancel}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {popupnewstatus &&
                (
                    <div className="font-sans justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-35">
                        <div className="md:text-sm 2xl:text-lg relative lg-[39rem] my-6 mx-auto  md:w-[28rem] w-[16rem]">
                            <div className="md:text-sm 2xl:text-lg border-solid border-2 border-red-700  rounded-md shadow-lg h-[11rem] bg-[#e1e8e7] relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                                <div className="items-start justify-between p-2 border-solid border-slate-200 rounded-t bg-red-700">
                                    <div
                                        className="flex p-1 rounded-b justify-between  "
                                    >
                                        <h2 className="md:text-xl 2xl:text-xl  font-semibold text-white text-xl">Alert</h2>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="17"
                                            height="17"
                                            viewBox="0 0 384 512"
                                            fill="#fff"
                                            className="mt-1.5 cursor-pointer"
                                            onClick={handlePopupnewCancel}

                                        >
                                            <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                                        </svg>
                                    </div>
                                </div>
                                <p className="md:text-sm 2xl:text-lg xl:text-lg lg:text-lg md:text-lg font-semibold justify-center text-sm items-center text-center mt-2">
                                    <p>{"Please choose Tax settings Tab as Product Wise"}</p>
                                </p>
                                <div className="flex p-2 border-solid border-slate-200 rounded-b md:justify-end sm:justify-end  mt-2">
                                    <div className="lg:flex lg:justify-end flex justify-end">

                                        <button
                                            className="md:text-xs 2xl:text-lg xl:text-lg lg:text-lg md:text-lg bg-red-700 text-white font-medium items-right justify-end mr-2 rounded-lg px-2 py-1 text-xs inline-flex "
                                            type="button"
                                            onClick={handlePopupnewCancel}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default InvoiceTypes
