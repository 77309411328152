// import Image from "next/image";
// import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import Banner from "../commen/Banner";
import invoivedata from "../invoices.json";
import MainInvoice from "./MainInvoice";
import { useLocation } from "react-router-dom";
const Gymfitnessinvoice = () => {
  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = (src: any) => {
    setSelectedImage(src);
  };
  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    if (selectedImage) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [selectedImage]);
  const [invoice, setInvoice] = useState("Simple Invoice");
  //   const pathname = usePathname();
  //   useEffect(() => {
  //     const pathParts = pathname.split("/").filter((part) => part);
  //     let name = pathParts[pathParts.length - 1] || "";

  //     if (name.endsWith(".html")) {
  //       name = name.slice(0, -5);
  //     }

  //     name = name
  //       .replace(/-/g, " ")
  //       .replace(/\b\w/g, (firstLetter as any) => firstLetter.toUpperCase());

  //     setInvoice(name);
  //   }, [pathname]);
  const images = [
    "Gym_Fitness_Invoice_template_1.webp",
    "Gym_Fitness_Invoice_template_2.webp",
    "Gym_Fitness_Invoice_template_3.webp",
    "Gym_Fitness_Invoice_template_4.webp",
    "Gym_Fitness_Invoice_template_5.webp",
    "Gym_Fitness_Invoice_template_6.webp",
  ];
  const processedName = location.pathname
    .replace("free-", "")
    .replace(/^\/|\.html$/g, "")
    .replace(/-invoice$/, "")

    .split("-")[0];

  const originalData = invoivedata.filter(
    (item) => item.value === processedName
  );

  const data = originalData[0];
  useEffect(() => {
    const pathParts = location.pathname.split("/").filter((part) => part);
    let name = pathParts[pathParts.length - 1] || "";

    if (name.endsWith(".html")) {
      name = name.slice(0, -5);
    }

    name = name

      .replace(/-/g, " ")
      .replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());

    setInvoice(name);
  }, [location.pathname]);
  return (
    <>
      <div
        className="bg-[white] lg:mx-4  xl:mx-0 xl:ml-20 xl:mr-20 xs:mx-1  "
        style={{ fontFamily: "poppins" }}
      >
        <Banner
          image={data.image}
          imageUrl1={data.imageUrl1}
          text={data.text}
          content={data.content}
          name={data.name}
        />
       <MainInvoice val={undefined} />
        <div className="bg-white mt-20 h-1/2  mx-8  ">
          <h4 className="text-center text-xl text-[#008065] pt-2 font-bold">
            What can you do with {invoice}?
          </h4>
          <div className="flex flex-wrap m-4 ml-12">
            <div className="w-full mb-4 md:w-1/2 mt-1 ">
              <div
                className="p-4 bg-white rounded-l lg:h-60  xl:h-64 md:h-72 md:mr-4 hover:shadow-sm font-semibold  text-sm text-left sm:text-left"
                style={{ fontFamily: "poppins" }}
              >
                <ul className="p-2 " style={{ fontFamily: "poppins" }}>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      Generate {invoice} in various currencies to accommodate
                      international clients.
                    </span>
                  </li>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      Options to add company logo, address, and business
                      information.
                    </span>
                  </li>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      A simple product/service catalog to list the items or
                      services provided, along with descriptions and prices.
                    </span>
                  </li>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      Export options such as PDF download or email directly to
                      clients.
                    </span>
                  </li>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      Track the status of {invoice} (e.g., paid, unpaid,
                      overdue).
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full mb-4 md:w-1/2 mt-1 ">
              <div
                className="p-4 bg-white rounded-l xl:h-64 lg:h-60 md:h-72 md:mr-4 hover:shadow-sm font-semibold  text-sm text-left"
                style={{ fontFamily: "poppins" }}
              >
                <ul className="p-2 " style={{ fontFamily: "poppins" }}>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      Include payment terms like due dates, late fees, and
                      accepted payment methods.
                    </span>
                  </li>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      Basic templates where you can customize the {invoice}
                      layout.
                    </span>
                  </li>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      Basic client database where you can store client names,
                      addresses, and contact details for future invoices.
                    </span>
                  </li>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      Ability to preview the {invoice} before sending.
                    </span>
                  </li>
                  <li className="text-xl" style={{ listStyleType: "circle" }}>
                    <span className=" text-xs font-semibold">
                      Limited financial reporting or analytics features for
                      tracking income.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8 px-4 lg:py-6 ">
          <div className="container mx-auto max-w-7xl 2xl:px-2">
            <div className="flex flex-col lg:flex-row   lg:justify-between">
              <div className="mb-8 lg:mb-0 lg:mr-8  flex justify-center ">
                <img
                  className="inline-block rounded-lg"
                  alt="free_gym_fitness_invoice"
                  width={600}
                  height={400}
                  src="/free_gym_fitness_invoice_image.webp"
                />
              </div>

              <div className=" lg:w-1/2 flex flex-col justify-center">
                <h4 className="flex justify-center text-[#008065] md:text-3xl lg:text-2xl    font-bold mb-4 lg:mb-5">
                  {invoice}
                </h4>
                <div className="flex justify-center">
                  <p className=" text-black-800 font-semibold text-justify  mb-5">
                  gym & Fitness Invoice is a billing document issued by a
                  fitness center or personal trainer named Jim & Fitness to
                  their clients. It includes details of services provided, such
                  as fitness training sessions, gym memberships, or other
                  related services. The invoice typically itemizes the fees for
                  each service, any applicable taxes, and payment terms. It
                  serves as a record of the financial transaction between Jim &
                  Fitness and its clients. it serve as a record of the services
                  and products
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center xl:justify-end md:justify-end text-[#008065] text-sm font-bold mt-2 flex px-4 ">
          <p className="mt-3 mr-[-25px]" style={{ fontFamily: "poppins" }}>
            Powered By
          </p>
          <a target="blank" href="https://nandalalainfotech.com">
            <img
              src="nandalala.green.svg"
              loading="lazy"
              alt="logo"
              style={{ height: "44px", width: "125px" }}
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Gymfitnessinvoice;
