/* eslint-disable jsx-a11y/anchor-is-valid */
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge } from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { AiOutlineStock } from "react-icons/ai";
import { BiCategoryAlt, BiFoodMenu, BiPurchaseTag } from "react-icons/bi";
import { BsCart4, BsFillPeopleFill } from "react-icons/bs";
import { FaUserCog } from "react-icons/fa";
import { FaArrowTrendDown, FaTruckMoving } from "react-icons/fa6";
import { GrMoney } from "react-icons/gr";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdAppSettingsAlt, MdDashboardCustomize, MdOutlinePayments, MdOutlineReceiptLong, MdProductionQuantityLimits } from "react-icons/md";
import { PiCaretDownBold, PiInvoiceBold } from "react-icons/pi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { SiExpensify } from "react-icons/si";
import { TiInfo } from "react-icons/ti";
import { VscFeedback } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";



const StyledDiv = styled("div")({
  position: "relative",
  marginRight: "0.5rem",
  marginTop: "0.75rem",
  overflowY: "auto",
  maxHeight: "75vh",
  "&::-webkit-scrollbar": {
    width: "7px",
    height: "7px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#FFFFFF",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#D9D5D5",
  },
});

interface props {
  isOpen: any;
  setsubMenuList: any;
  toggleSidebar: any;
  toggleDropdown: any;
  subMenuList: any;
  closeSideBar: any;
  openSideBar: any;
}

const Sidebar = ({
  isOpen,

  toggleSidebar,

  closeSideBar,
  openSideBar,
}: props) => {

  const localStorages: any = sessionStorage.getItem("userInfo");
  const token = JSON.parse(localStorages as any);
  const navigateTo = useNavigate();
  const location = useLocation();
  const params = useParams();
  const pathname = location.pathname;
  const [menu, Setmenu] = useState(false);
  const [invoicebasemenu, SetInvoiceBasemenu] = useState(false);



  const [purchasebasemenu, SetpurchaseBasemenu] = useState(false);



  const usersignin = useSelector((state: any) => state.userSignin);
  const { userInfo } = usersignin;

  const count = useSelector((state: any) => state.NotificationCount);
  const { notificationcount } = count;

  const signoutHandler = () => {

    sessionStorage.removeItem("userInfo");

    if (window.location.hostname === "localhost") {
      navigateTo('/')
    } else {
      window.location.href = "https://invoicefree.in/";
    }
  };

  const hanldeDashboardNavigate = () => {
    navigateTo("/app/home");
    Setmenu(false);


    SetpurchaseBasemenu(false);
    SetInvoiceBasemenu(false)
  };

  const hanldeClientNavigate = () => {
    navigateTo("/app/client");
    Setmenu(false);


    SetpurchaseBasemenu(false)
    SetInvoiceBasemenu(false)
  };
  const hanldeExpenseNavigate = () => {
    navigateTo("/app/expenseCategeroy");
    Setmenu(false);


    SetpurchaseBasemenu(false)
    SetInvoiceBasemenu(false)
  };

  const hanldeExpensePage = () => {
    navigateTo("/app/expensePageTable");
    Setmenu(false);


    SetpurchaseBasemenu(false)
    SetInvoiceBasemenu(false)
  };
  const hanldeInvoiceNavigate = () => {
    if (!params.id) {
      navigateTo("/app/invoice");
      Setmenu(false);


      SetpurchaseBasemenu(false);
      SetInvoiceBasemenu(false)
    }
  };

  const hanldeSalesReturnNavigate = () => {
    if (!params.id) {
      navigateTo("/app/salesReturnTab");
      Setmenu(false);


      SetpurchaseBasemenu(false);

    }
  };

  const hanldeProductNavigate = () => {
    navigateTo("/app/product");
    Setmenu(false);


    SetpurchaseBasemenu(false)
    SetInvoiceBasemenu(false)
  };
  const hanldeVendorNavigate = () => {
    navigateTo("/app/vendors");
    Setmenu(false);


    SetpurchaseBasemenu(false)
    SetInvoiceBasemenu(false)
  };
  const hanldeCompanyNavigate = () => {
    navigateTo("/app/company");
  };
  // const hanldePurchaseNavigate = () => {
  //   navigateTo("/app/purchase");
  //   Setmenu(false);
  //   
  //   
  //   
  // };
  const hanldeSalesNavigate = () => {
    navigateTo("/app/sales");

  };

  const hanldeQuotationNavigate = () => {
    navigateTo("/app/quotation");

  };

  const hanldeDeliveyChallanNavigate = () => {
    navigateTo("/app/deliveryChallanTab");

  };

  const hanldePaymentsNavigate = () => {
    navigateTo("/app/payments");
  };
  const hanldePaymentOutNavigate = () => {
    navigateTo("/app/paymentsOut");

  };
  const hanldeStockNavigate = () => {
    navigateTo("/app/stock");


    SetpurchaseBasemenu(false)
    SetInvoiceBasemenu(false)
  };
  const hanldePurchaseNavigate = () => {
    navigateTo("/app/purchase");
  }
  const hanldeAppSettingNavigate = () => {
    navigateTo("/app/appSetting");
  };
  const hanldeUserSettingNavigate = () => {
    navigateTo("/app/usersetting");
  };
  const hanldeFeedbackNavigate = () => {
    navigateTo("/app/feedback");
    Setmenu(false);


    SetpurchaseBasemenu(false)
    SetInvoiceBasemenu(false)
  };
  const hanldeNotificationNavigate = () => {
    navigateTo("/app/notification");
    Setmenu(false);


    SetpurchaseBasemenu(false)
    SetInvoiceBasemenu(false)
  };

  const hanldeSubmenu = () => {
    Setmenu(!menu);


    SetpurchaseBasemenu(false)
    SetInvoiceBasemenu(false)
    navigateTo("/app/company");
  };
  const hanldeInvoiceBaseSubmenu = () => {
    SetInvoiceBasemenu(!invoicebasemenu);

    SetpurchaseBasemenu(false)

    Setmenu(false);
    navigateTo("/app/sales");
  };


  const hanldePurchaseBaseSubmenu = () => {
    SetpurchaseBasemenu(!purchasebasemenu);
    SetInvoiceBasemenu(false)


    Setmenu(false);
    navigateTo("/app/purchase");
  };
  const hanldeDashboardAdminNavigate = () => {
    navigateTo("/app/AdminDashboard");
  };
  const hanldeUnitsNavigate = () => {
    navigateTo("/app/Units");
  };
  const hanldeUsersNavigate = () => {
    navigateTo("/app/Users");
  };
  const hanldeAdminPaymentNavigate = () => {
    navigateTo("/app/AdminPayment");
  };
  const hanldeExternalInvoiceNavigate = () => {
    navigateTo("/app/ExternalInvoice");
  };

  const hanldePurchaseReturnNavigate = () => {
    navigateTo("/app/purchaseReturn");
  }
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 900px)").matches,
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 900px)")
      .addEventListener("change", (e) => setMatches(e.matches));
    if (matches) closeSideBar();
    else openSideBar();
  }, [matches]);

  return (
    <div
      className={`fixed sidebarTop z-10 left-0 h-full w-64  nav-width transition -transform duration-300 ease-in-out transform
         ${matches && isOpen && !pathname.includes("invoice")
          ? "bg-[#E1E8E7]"
          : matches && isOpen && pathname.includes("invoice")
            ? "bg-[#fff]"
            : "bg-[#F1F7F6]"
        }
         ${isOpen && !matches && "-translate-x-52 ease-out"}   
         ${isOpen && matches && "-translate-x-60 ease-out"}
         ${!isOpen && matches && "-translate-x-52 ease-out"}
         `}
    >
      {matches && isOpen ? (
        <div className="flex justify-end mt-3" aria-label="Breadcrumb">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-6"
            style={{ marginRight: "-8px", marginTop: "2px" }}
            stroke={"#008065"}
            viewBox="0 0 24 24"
            id="menu"
            onClick={toggleSidebar}
          >
            <g>
              <g>
                <rect
                  width="18"
                  height="2"
                  x="3"
                  y="11"
                  rx=".95"
                  ry=".95"
                ></rect>
                <rect
                  width="18"
                  height="2"
                  x="3"
                  y="16"
                  rx=".95"
                  ry=".95"
                ></rect>
                <rect
                  width="18"
                  height="2"
                  x="3"
                  y="6"
                  rx=".95"
                  ry=".95"
                ></rect>
              </g>
            </g>
          </svg>
        </div>
      ) : (
        <></>
      )}

      <div className="  flex justify-between  mx-3">
        <img
          src="/logoheader.webp"
          alt="logo"
          className=" w-24 mt-4"
          style={{ height: "32px" }}
        />

        <motion.button
          className={` lg:block p-2 focus:outline-none rounded-md ${matches && "invisible"
            }`}
          onClick={toggleSidebar}
          initial={{
            translateX: 0,
          }}
          animate={{
            color: isOpen ? "#777" : "#0066FF",
            rotate: isOpen ? "360deg" : "0deg",
          }}
          transition={{
            type: "spring",
            damping: 25,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 ml-[6rem]"
            fill="none"
            viewBox="0 0 24 24"
            stroke={isOpen ? "#008065" : "#008065"}
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d={isOpen ? "M4 6h16M4 12h16M4 18h7" : "M15 19l-7-7 7-7"}
            />
          </svg>
        </motion.button>
        <motion.button
          className={`${!matches && "invisible"} ${isOpen && "invisible"}`}
          onClick={toggleSidebar}
          initial={{
            translateX: 0,
          }}
          transition={{
            type: "spring",
            damping: 25,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-[1.8rem] ml-[5px] "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            transform="translate(-40)"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d={false ? "M4 6h16M4 12h16M4 18h7" : "M4 6h16M4 12h16M4 18h7"}
            />
          </svg>
        </motion.button>
      </div>
      <StyledDiv>
        <ul className="space-y-2 font-medium text-white">
          {userInfo.token?.userRoleName === "Admin" ? (
            <>

              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeDashboardNavigate}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/home"
                        ? "cursor-pointer flex items-center bg-[#008065] p-2 text-white rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group md:flex"
                        : "cursor-pointer flex items-center p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group md:flex"
                  }
                >
                  <MdDashboardCustomize className="h-6 w-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Dashboard</span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end " : ""
                  }`}
                onClick={hanldeInvoiceNavigate}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/invoice" || location.pathname === `/app/invoice/${params.id}`
                        ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >

                  <LiaFileInvoiceDollarSolid className="w-6 h-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Create Invoice </span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeClientNavigate}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/client"
                        ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >
                  <BsFillPeopleFill className="h-6 w-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Clients</span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end " : ""
                  }`}
                onClick={hanldeVendorNavigate}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/vendors"
                        ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >
                  <FaTruckMoving className="w-6 h-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Vendors</span>
                  </span>
                </a>
              </li>
              {/*---------------------- Product Base Menu Starts -----------------*/}
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeProductNavigate}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/product"
                        ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >
                  <MdProductionQuantityLimits className="w-6 h-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >

                    <span>Products</span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeInvoiceBaseSubmenu}
              >
                <span>
                  <button
                    type="button"
                    className={
                      matches && isOpen
                        ? "hidden border-none"
                        : location.pathname === "/app/sales" || location.pathname === "/app/salesReturnTab" || location.pathname === "/app/salesreturn" || location.pathname === `/app/salesreturn/${params.id}`
                          || location.pathname === "/app/quotation" || location.pathname === "/app/deliveryChallan" || location.pathname === "/app/deliveryChallanTab"
                          || location.pathname === `/app/payments` || location.pathname === `/app/paymentsReceive/${params.id}` || location.pathname === `/app/paymentsReceive`
                          ? "cursor-pointer w-full flex items-center text-[#008065]  p-2 rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex border border-[#008065]"
                          : `flex items-center  w-full p-2 text-base text-[#008065] transition duration-75 rounded-lg group hover:bg-white-100 dark:text-white dark:hover:bg-white-700 `
                    }
                    aria-controls="dropdown-example"
                    data-collapse-toggle="dropdown-example"
                  >
                    <PiInvoiceBold className="w-6 h-6" />
                    <span
                      className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                        }`}
                    >
                      <span> Sales</span>
                    </span>
                    <PiCaretDownBold
                      className={isOpen || matches ? "hidden" : "w-6 h-6"} />
                  </button>
                </span>
              </li>
              {/* -----------Invoice  Sub Menu ------------- */}

              {(location.pathname === "/app/sales" && invoicebasemenu) ||
                (location.pathname === "/app/salesReturnTab" && invoicebasemenu) ||
                (location.pathname === "/app/payments" && invoicebasemenu) ||
                (location.pathname === "/app/deliveryChallanTab" && invoicebasemenu) ||
                (location.pathname === "/app/salesreturn"
                  || location.pathname === "/app/quotation"
                  || location.pathname === "/app/deliveryChallan"
                  || location.pathname === `/app/paymentsReceive/${params.id}`
                  || location.pathname === `/app/paymentsReceive`) ? (
                <>
                  <ul id="dropdown-example" className=" py-2 space-y-2">
                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldeSalesNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/sales"
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >

                        <GrMoney className="w-6 h-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Sales Report </span>
                        </span>
                      </a>
                    </li>


                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldeSalesReturnNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/salesReturnTab" || location.pathname === `/app/salesreturn/${params.id}` || location.pathname === "/app/salesreturn"
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >

                        <FaArrowTrendDown className="w-6 h-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Sales Return</span>
                        </span>
                      </a>
                    </li>

                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldeQuotationNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/quotation"
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >

                        <MdOutlineReceiptLong className="w-6 h-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Quotation</span>
                        </span>
                      </a>
                    </li>

                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldeDeliveyChallanNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/deliveryChallan" || location.pathname === "/app/deliveryChallanTab" || location.pathname === `/app/deliveryChallan/${params.id}`
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >

                        <BiFoodMenu className="w-6 h-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Delivery Challan</span>
                        </span>
                      </a>
                    </li>

                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldePaymentsNavigate}
                    >
                      <a

                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/payments" || location.pathname === `/app/paymentsReceive/${params.id}` || location.pathname === `/app/paymentsReceive`
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }

                      >
                        <RiSecurePaymentLine className="h-6 w-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Payments In</span>
                        </span>
                      </a>
                    </li>

                  </ul>
                </>
              ) : (
                <></>
              )}
              {/*----------------------- Invoice Menu Ends ------------------*/}

              {/* -----------PRODUCT BASE Sub Menu ------------- */}




              {/*-------------- PRODUCT BASE MENU ENDS --------------*/}


              {/*---------------------- Service Base Menu Starts -----------------*/}
              {/* <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeServiceBaseSubmenu}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/service"
                        ? `cursor-pointer flex bitems-center text-[#008065]  p-2 rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex border border-[#008065]`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >


                  <FontAwesomeIcon icon={faHandHoldingHeart} className="w-6 h-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Service Base</span>
                  </span>
                  <PiCaretDownBold
                    className={isOpen || matches ? "hidden" : "w-6 h-6"} />
                </a>
              </li> */}
              {/* -----------Service Sub Menu ------------- */}
              {/* {(location.pathname === "/app/service" && servicebasemenu) ? (
                <>
                  <ul id="dropdown-example" className=" py-2 space-y-2">
                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldeServiceNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/service"
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >

                        <MdDesignServices className="w-6 h-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Service</span>
                        </span>
                      </a>
                    </li>


                  </ul>
                </>
              ) : (
                <></>
              )} */}


              {/*----------------------- Service Menu Ends ------------------*/}
              <li
                className={`${isOpen || matches ? "flex justify-end " : ""
                  }`}
                onClick={hanldePurchaseBaseSubmenu}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/purchase" || location.pathname === "/app/purchaseReturn" || location.pathname === `/app/purchaseReturnInvoice/${params.id}` ||
                        location.pathname === `/app/purchasePage/${params.id}` || location.pathname === `/app/purchaseReturnInvoice` || location.pathname === `/app/purchasePage` ||
                        location.pathname === `/app/paymentsOut` || location.pathname === `/app/paymentOutPage/${params.id}`
                        ? `cursor-pointer flex bitems-center text-[#008065]  p-2  rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex  border border-[#008065]`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >
                  <BiPurchaseTag className="w-6 h-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Purchase</span>
                  </span>
                  <PiCaretDownBold
                    className={isOpen || matches ? "hidden" : "w-6 h-6"} />
                </a>
              </li>
              {/* -----------Purchase Sub Menu ------------- */}
              {(location.pathname === "/app/purchase" && purchasebasemenu) ||
                (location.pathname === "/app/purchaseReturn" && purchasebasemenu) ||
                (location.pathname === "/app/paymentsOut" && purchasebasemenu) ||
                (location.pathname === `/app/purchaseReturnInvoice/${params.id}`
                  || location.pathname === `/app/purchasePage/${params.id}`
                  || location.pathname === `/app/purchasePage`
                  || location.pathname === `/app/purchaseReturnInvoice`
                  || location.pathname === `/app/paymentOutPage/${params.id}`) ? (
                <>
                  <ul id="dropdown-example" className=" py-2 space-y-2">
                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldePurchaseNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/purchase" || location.pathname === `/app/purchasePage` || location.pathname === `/app/purchasePage/${params.id}`
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >

                        <BsCart4 className="w-6 h-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Purchase Report</span>
                        </span>
                      </a>
                    </li>
                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldePurchaseReturnNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/purchaseReturn" || (location.pathname === `/app/purchaseReturnInvoice/${params.id}` || location.pathname === `/app/purchaseReturnInvoice`)
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg   md:flex"
                        }
                      >
                        <GrMoney className="w-6 h-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Purchase Return</span>
                        </span>
                      </a>
                    </li>

                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldePaymentOutNavigate}
                    >
                      <a

                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/paymentsOut" || (location.pathname === `/app/paymentOutPage/${params.id}`)
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }

                      >
                        <MdOutlinePayments className="h-6 w-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Payments Out</span>
                        </span>
                      </a>
                    </li>

                  </ul>
                </>
              ) : (
                <></>
              )}
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeExpenseNavigate}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/expenseCategeroy"
                        ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >
                  <BiCategoryAlt className="h-6 w-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Expense Category</span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeExpensePage}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/expensePageTable" || location.pathname === "/app/expensePage" || location.pathname === `/app/expensePage/${params.id}`
                        ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >
                  <SiExpensify className="h-6 w-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Expense</span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end " : ""
                  }`}
                onClick={hanldeStockNavigate}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/stock"
                        ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >
                  <AiOutlineStock className="w-6 h-6" />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Stock</span>
                  </span>
                </a>
              </li>


              {/*----------------------- Purchase Menu Ends ------------------*/}



              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeSubmenu}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/company" ||
                        location.pathname === "/app/appSetting" ||
                        location.pathname === "/app/usersetting" ||
                        location.pathname === "/app/serviceSettings"
                        ? `cursor-pointer flex bitems-center text-[#008065]  p-2  rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex border border-[#008065]`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={
                      location.pathname === "/app/company" ||
                        location.pathname === "/app/appSetting" ||
                        location.pathname === "/app/usersetting" ||
                        location.pathname === "/app/serviceSettings"
                        ? " text-[#008065] w-6 h-6 "
                        : " text-[#008065] w-6 h-6 "
                    }
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                    />
                    <path
                      strokeLinecap="round"
                      fill={
                        location.pathname === "/app/company" ||
                          location.pathname === "/app/appSetting" ||
                          location.pathname === "/app/usersetting" ||
                          location.pathname === "/app/servicesetting"
                          ? "#008065"
                          : "#008065"
                      }
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Settings</span>
                  </span>
                  <PiCaretDownBold
                    className={isOpen || matches ? "hidden" : "w-6 h-6"} />
                </a>
              </li>
              {/* -----------Settings ------------- */}
              {(location.pathname === "/app/company" && menu) ||
                (location.pathname === "/app/appSetting" && menu) ||
                (location.pathname === "/app/usersetting" && menu) ||
                (location.pathname === "/app/serviceSettings" && menu) ? (


                <>
                  <ul id="dropdown-example" className=" py-2 space-y-2">
                    <li
                      className={`${isOpen || matches ? "flex justify-end " : "ml-11"
                        }`}
                      onClick={hanldeCompanyNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/company"
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >
                        <TiInfo className="h-6 w-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Info Settings</span>
                        </span>
                      </a>
                    </li>

                    <li
                      className={`${isOpen || matches ? "flex justify-end" : "ml-11"
                        }`}
                      onClick={hanldeAppSettingNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/appSetting"
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >
                        <MdAppSettingsAlt className="h-6 w-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>App Settings</span>
                        </span>
                      </a>
                    </li>

                    <li
                      className={`${isOpen || matches ? "flex justify-end" : "ml-11"
                        }`}
                      onClick={hanldeUserSettingNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/usersetting"
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >
                        <FaUserCog className="h-6 w-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>User Settings</span>
                        </span>
                      </a>
                    </li>

                    {/* <li
                      className={`${isOpen || matches ? "flex justify-end" : "ml-11"
                        }`}
                      onClick={hanldeServiceSettingsNavigate}
                    >
                      <a
                        className={
                          matches && isOpen
                            ? "hidden border-none"
                            : location.pathname === "/app/serviceSettings"
                              ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                              : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                        }
                      >
                        <FontAwesomeIcon icon={faSliders} className="w-6 h-6" />
                        <span
                          className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                            }`}
                        >
                          <span>Service Settings</span>
                        </span>
                      </a>
                    </li> */}

                    <li className="mb-2 pl-11">

                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}



              {/*--------------- Feedback----------- */}
              {userInfo.token?.userRoleName === "Admin" && (
                <>
                  <li
                    className={`${isOpen || matches ? "flex justify-end" : ""}`}
                    onClick={hanldeFeedbackNavigate}
                  >
                    <a
                      className={
                        matches && isOpen
                          ? "hidden border-none"
                          : location.pathname === "/app/feedback"
                            ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                            : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                      }
                    >
                      <VscFeedback className="h-6 w-6" />
                      <span
                        className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                          }`}
                      >
                        <span>Feedback</span>
                      </span>
                    </a>
                  </li>
                </>
              )}
            </>
          ) : (
            <>

              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeDashboardAdminNavigate}
              >
                <a
                  className={` ${isOpen && matches && "bg-inherit"}
                  ${location.pathname === "/app/AdminDashboard"
                      ? "cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                      : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                    }`}
                >
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 23 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.7222 20.4564V9.1549H22.5V20.4564H12.7222ZM0.5 11.4152V0.113708H10.2778V11.4152H0.5ZM7.83333 9.1549V2.37401H2.94444V9.1549H7.83333ZM0.5 20.4564V13.6755H10.2778V20.4564H0.5ZM2.94444 18.1961H7.83333V15.9358H2.94444V18.1961ZM15.1667 18.1961H20.0556V11.4152H15.1667V18.1961ZM12.7222 0.113708H22.5V6.8946H12.7222V0.113708ZM15.1667 2.37401V4.6343H20.0556V2.37401H15.1667Z"
                      fill={
                        location.pathname === "/app/AdminDashboard"
                          ? "#F1F7F6"
                          : "#008065"
                      }
                    />
                  </svg>
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Dashboard</span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeUsersNavigate}
              >
                <a
                  className={` ${isOpen && matches && "bg-inherit"}
                   ${location.pathname === "/app/Users"
                      ? "cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                      : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                    }`}
                >
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 101 101"
                    id="user"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      d="M50.4 54.5c10.1 0 18.2-8.2 18.2-18.2S60.5 18 50.4 18s-18.2 8.2-18.2 18.2 8.1 18.3 18.2 18.3zm0-31.7c7.4 0 13.4 6 13.4 13.4s-6 13.4-13.4 13.4S37 43.7 37 36.3s6-13.5 13.4-13.5zM18.8 83h63.4c1.3 0 2.4-1.1 2.4-2.4 0-12.6-10.3-22.9-22.9-22.9H39.3c-12.6 0-22.9 10.3-22.9 22.9 0 1.3 1.1 2.4 2.4 2.4zm20.5-20.5h22.4c9.2 0 16.7 6.8 17.9 15.7H21.4c1.2-8.9 8.7-15.7 17.9-15.7z"
                    ></path>
                  </svg>
                  <span
                    style={{ marginLeft: "6px" }}
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Users</span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeUnitsNavigate}
              >
                <a
                  className={` ${isOpen && matches && "bg-inherit"}
                   ${location.pathname === "/app/Units"
                      ? "cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                      : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                    }`}
                >
                  <svg
                    viewBox="0 0 512 512"
                    className="h-4 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m510.28 445.86-73.03-292.13c-3.8-15.19-16.44-25.72-30.87-25.72h-60.25c3.57-10.05 5.88-20.72 5.88-32 0-53.02-42.98-96-96-96s-96 42.98-96 96c0 11.28 2.3 21.95 5.88 32h-60.25c-14.43 0-27.08 10.54-30.87 25.72l-73.05 292.13c-8.33 33.31 14.66 66.14 46.31 66.14h415.95c31.64 0 54.63-32.83 46.3-66.14zm-254.28-317.86c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32z"
                      fill={
                        location.pathname === "/app/Units"
                          ? "#F1F7F6"
                          : "#008065"
                      }
                    />
                  </svg>
                  <span
                    style={{ marginLeft: "10px" }}
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Units</span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeAdminPaymentNavigate}
              >
                <a
                  className={` ${isOpen && matches && "bg-inherit"}
                   ${location.pathname === "/app/AdminPayment"
                      ? "cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                      : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    color=""
                    viewBox="0 0 28 25"
                    id="payment"
                  >
                    <g>
                      <path
                        d="M25.42,19.67 C25.1438576,19.67 24.92,19.8938576 24.92,20.17 L24.92,22 C24.92,22.8284271 24.2484271,23.5 23.42,23.5 L3,23.5 C2.17157288,23.5 1.5,22.8284271 1.5,22 L1.5,6 C1.5,5.17157288 2.17157288,4.5 3,4.5 L7.91,4.5 C8.18614237,4.5 8.41,4.27614237 8.41,4 C8.41,3.72385763 8.18614237,3.5 7.91,3.5 L3,3.5 C1.61928813,3.5 0.5,4.61928813 0.5,6 L0.5,22 C0.5,23.3807119 1.61928813,24.5 3,24.5 L23.42,24.5 C24.7984352,24.4945191 25.9145191,23.3784352 25.92,22 L25.92,20.17 C25.92,19.8938576 25.6961424,19.67 25.42,19.67 Z"
                        fill={
                          location.pathname === "/app/AdminPayment"
                            ? "#F1F7F6"
                            : "#008065"
                        }
                      ></path>
                      <path
                        d="M26,11.31 L25.92,11.31 L25.92,6 C25.9145191,4.62156478 24.7984352,3.50548092 23.42,3.5 C23.1438576,3.5 22.92,3.72385763 22.92,4 C22.92,4.27614237 23.1438576,4.5 23.42,4.5 C24.2484271,4.5 24.92,5.17157288 24.92,6 L24.92,6.28 L24.36,6.28 L21.36,3.28 L19.18,1.1 C19.0861167,1.00534367 18.9583188,0.952100838 18.825,0.952100838 C18.6916812,0.952100838 18.5638833,1.00534367 18.47,1.1 L15.83,3.74 L13.18,1.1 C12.9821863,0.90821671 12.6678137,0.90821671 12.47,1.1 L7.29,6.28 L5.5,6.28 C5.22385763,6.28 5,6.50385763 5,6.78 C5,7.05614237 5.22385763,7.28 5.5,7.28 L24.92,7.28 L24.92,11.28 L22,11.28 C20.0670034,11.28 18.5,12.8470034 18.5,14.78 L18.5,15.16 C18.5,17.0929966 20.0670034,18.66 22,18.66 L26,18.66 C26.8284271,18.66 27.5,17.9884271 27.5,17.16 L27.5,12.81 C27.5,11.9815729 26.8284271,11.31 26,11.31 Z M18.83,2.16 L20.28,3.61 C19.4031054,4.19916775 18.2568946,4.19916775 17.38,3.61 L18.83,2.16 Z M16.67,4.32 C17.945453,5.29354535 19.714547,5.29354535 20.99,4.32 L22.99,6.32 L18.36,6.32 L16.53,4.45 L16.67,4.32 Z M10.87,6.32 L13.87,3.32 L14.57,4.02 L12.27,6.28 L10.87,6.32 Z M15.32,4.69 L17,6.28 L13.68,6.28 L15.32,4.69 Z M12.83,2.16 L13.2,2.53 L9.45,6.28 L8.71,6.28 L12.83,2.16 Z M26.5,17.16 C26.5,17.4361424 26.2761424,17.66 26,17.66 L22,17.66 C21.3369588,17.66 20.701074,17.3966079 20.232233,16.927767 C19.7633921,16.458926 19.5,15.8230412 19.5,15.16 L19.5,14.78 C19.5,13.3992881 20.6192881,12.28 22,12.28 L26,12.28 C26.2761424,12.28 26.5,12.5038576 26.5,12.78 L26.5,17.16 Z"
                        fill={
                          location.pathname === "/app/AdminPayment"
                            ? "#F1F7F6"
                            : "#008065"
                        }
                      ></path>
                      <circle cx="22.08" cy="15" r="1"></circle>
                    </g>
                  </svg>
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Payments</span>
                  </span>
                </a>
              </li>
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeExternalInvoiceNavigate}
              >
                <a
                  className={` ${isOpen && matches && "bg-inherit"}
                   ${location.pathname === "/app/ExternalInvoice"
                      ? "cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                      : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                    }`}
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  // onClick={handleClicke}
                  // className={isClickeed ? "white" : ""}
                  >
                    <path
                      d="M15.5 19.925L11.25 15.675L12.65 14.275L15.5 17.125L21.15 11.475L22.55 12.875L15.5 19.925ZM21 10H19V5H17V8H7V5H5V19H11V21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9.175C9.35833 2.41667 9.71667 1.9375 10.25 1.5625C10.7833 1.1875 11.3667 1 12 1C12.6667 1 13.2625 1.1875 13.7875 1.5625C14.3125 1.9375 14.6667 2.41667 14.85 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V10ZM12 5C12.2833 5 12.5208 4.90417 12.7125 4.7125C12.9042 4.52083 13 4.28333 13 4C13 3.71667 12.9042 3.47917 12.7125 3.2875C12.5208 3.09583 12.2833 3 12 3C11.7167 3 11.4792 3.09583 11.2875 3.2875C11.0958 3.47917 11 3.71667 11 4C11 4.28333 11.0958 4.52083 11.2875 4.7125C11.4792 4.90417 11.7167 5 12 5Z"
                      fill={
                        location.pathname === "/app/ExternalInvoice"
                          ? "white"
                          : "#008065"
                      }
                    />
                  </svg>
                  <span
                    style={{ marginLeft: "9px" }}
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>External Invoices</span>
                  </span>
                </a>
              </li>
            </>
          )}
        </ul>
      </StyledDiv>
      <div className=" div-content absolute bottom-0">
        <ul className="space-y-2 font-medium [#008065] ">
          {userInfo.token?.userRoleName === "Admin" && (
            <>
              <li
                className={`${isOpen || matches ? "flex justify-end" : ""}`}
                onClick={hanldeNotificationNavigate}
              >
                <a
                  className={
                    matches && isOpen
                      ? "hidden border-none"
                      : location.pathname === "/app/notification"
                        ? `cursor-pointer flex bitems-center bg-[#008065]  p-2 text-[white] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex`
                        : "cursor-pointer flex bitems-center  p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group  md:flex"
                  }
                >
                  <Badge
                    badgeContent={notificationcount?.total_count}
                    color="error"
                  >
                    <NotificationsIcon
                      fill={
                        location.pathname === "/app/notification"
                          ? "#F1F7F6"
                          : "#008065"
                      }
                    />
                  </Badge>

                  <span
                    className={`flex-1 ml-3 whitespace-nowrap  text-sm font-bold ${isOpen || matches ? "hidden" : ""
                      }`}
                  >
                    <span>Notification</span>
                  </span>
                </a>
              </li>
            </>
          )}

          <hr className="w-64 h-px my-1 bg-gray-200 border-0 dark:bg-gray-700 "></hr>
          <li className={`${isOpen || matches ? "flex justify-end" : ""}`}>
            <a className="flex items-center p-2 text-[#008065] rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-white-700 group">
              <ul className={`${isOpen || matches ? "hidden" : ""}`}>
                <li className="w-48 ml-3 [#008065]  text-sm font-bold   cursor-default cursor-pointer truncate">
                  {token?.token?.email}
                </li>
              </ul>
              <div
                className={` ${isOpen || matches
                  ? "mr-2"
                  : "fixed -mt-1 ml-[220px] cursor-pointer"
                  }`}
                onClick={signoutHandler}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-shrink-1 text-[#008065]  transition duration-75 "
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                    stroke="#008065"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div >
  );
};

export default Sidebar;