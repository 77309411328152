export const QUOTATION_SERVICEINVOICE_POST_REQUEST = 'QUOTATION_SERVICEINVOICE_POST_REQUEST';
export const QUOTATION_SERVICEINVOICE_POST_SUCCESS = 'QUOTATION_SERVICEINVOICE_POST_SUCCESS';
export const QUOTATION_SERVICEINVOICE_POST_FAIL = 'QUOTATION_SERVICEINVOICE_POST_FAIL';
export const QUOTATION_SERVICEINVOICE_POST_REST = 'QUOTATION_SERVICEINVOICE_POST_REST';

export const QUOTATION_SERVICEINVOICE_FIND_ALL_REQUEST  = 'QUOTATION_SERVICEINVOICE_FIND_ALL_REQUEST';
export const QUOTATION_SERVICEINVOICE_FIND_ALL_SUCCESS  = 'QUOTATION_SERVICEINVOICE_FIND_ALL_SUCCESS';
export const QUOTATION_SERVICEINVOICE_FIND_ALL_FAIL  = 'QUOTATION_SERVICEINVOICE_FIND_ALL_FAIL';
export const QUOTATION_SERVICEINVOICE_FIND_ALL_RESET  = 'QUOTATION_SERVICEINVOICE_FIND_ALL_RESET';

export const QUOTATION_SERVICEINVOICE_FIND_ONE_REQUEST = 'QUOTATION_SERVICEINVOICE_FIND_ONE_REQUEST';
export const QUOTATION_SERVICEINVOICE_FIND_ONE_SUCCESS = 'QUOTATION_SERVICEINVOICE_FIND_ONE_SUCCESS';
export const QUOTATION_SERVICEINVOICE_FIND_ONE_FAIL = 'QUOTATION_SERVICEINVOICE_FIND_ONE_FAIL';
export const QUOTATION_SERVICEINVOICE_FIND_ONE_RESET = 'QUOTATION_SERVICEINVOICE_FIND_ONE_RESET';

export const QUOTATION_SERVICEINVOICE_DELETE_REQUEST = 'QUOTATION_SERVICEINVOICE_DELETE_REQUEST';
export const QUOTATION_SERVICEINVOICE_DELETE_SUCCESS = 'QUOTATION_SERVICEINVOICE_DELETE_SUCCESS';
export const QUOTATION_SERVICEINVOICE_DELETE_FAIL = 'QUOTATION_SERVICEINVOICE_DELETE_FAIL';
export const QUOTATION_SERVICEINVOICE_DELETE_RESET = 'QUOTATION_SERVICEINVOICE_DELETE_RESET';



