/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "@mui/material";
import moment from "moment";
import { toWords } from "number-to-words";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useData } from "../../layouts/shared/DataProvider";
import { fontFamilyGet } from "../../redux/actions/FontFamilyAction";
import {
  InternalGetCount,
  InternalInvoiceUpdate
} from "../../redux/actions/InternalInvoiceAction";
import { currencyGet } from "../../redux/actions/currencyAction";
import { PurchaseFindAll } from "../../redux/actions/purchasePageNewAction";
import { PchaseReturnEdit, purchaseInternalFindOne, purchaseReturnPost } from "../../redux/actions/purchaseReturnActions";
import { registerAll } from "../../redux/actions/userAction";
import api from "../../redux/axiosConfig";
import { encryptData } from "../../redux/cryptoConfig";
import ClientPlusIcon from "../Icons/ClientPlusIcon";
import ConfirmationDownload from "../commen/ConfirmationDownload";
import NumberFormat from "../commen/NumberFormat";
import PaymentUpdateModel from "../commen/PaymentUpdateModel";
import { defaultInputSmStyle } from "../commen/defaultStyles";
import SendEmailModel from "../models/SendEmailModel";
import VendorModel from "../models/VendorModel";
import "./../../layouts/App/App.css";
import GstSection from "./InvoiceDetails/GstSection";
import Preview from "./Preview";
import PreviewNewProductwise from "./PreviewNewProductwise";
import Print from "./Print";
import PrintProductwise from "./PrintProductwise";
import PurchaseNewReturnProducts from "./PurchaseDetails/PurchaseNewReturnProducts";
import PurchaseReturnProduct from "./PurchaseDetails/PurchaseReturnProduct";


function PurchaseReturn() {

  const CustomInputWithIcon = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className='relative'>
      <input
        type="text"
        value={value}
        readOnly
        className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12 "}
        style={{
          fontFamily: "poppins",
          fontSize: "14px",
          fontWeight: 400,
          borderColor: "#008065",
        }}
        ref={ref}
      />
      <FaCalendarAlt
        className="absolute right-0 top-0 mt-2 mr-2   cursor-pointer lg:right-2  lg:mr-0  xl:right-12  xl:mr-9   2xl:right-8  2xl:mr-4"
        onClick={onClick}
      />
    </div>
  ));
  // const ref = useRef(null);
  const [isToggled, setIsToggled] = useState(true);
  const ref = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch<any>();
  const params = useParams();
  const { id } = params;
  const usersignin = useSelector((state: any) => state?.userSignin);
  const token = usersignin?.userInfo?.token;
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const { selectedFont, setSelectedFont }: any = useData();
  const [invoiceUpdateId, setInvoiceUpdateId] = useState(null as any);
  const [invoiceSelectId, setSelectId] = useState(null as any);
  const [purchaseList, setPurchaseList] = useState<any[]>([]);
  const [TaxAllList, setTaxAllList] = useState([] as any);
  const [selectedVendorId, setselectedVendorId] = useState(null as any);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [balanceAmount, setbalanceAmount] = useState(0 as any);
  const [CashAmount, setCashAmount] = useState(0 as any);
  const [receivedAmount, setreceivedAmount] = useState(0 as any);
  const [purchaseReturnDate, setPurchaseReturnDate] = useState(new Date() as any);
  const formatDate = moment(purchaseReturnDate).format("DD/MM/YYYY");
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  // ***********************************************************************

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [formData, setFormData] = useState({
    errorMessage: "",
    errors: {},
    paymentInReceiptNo: "",
    invoicehead: "PURCHASE RETURN",
    invoiceNo: "",
    purchaseReturnDate: formatDate,
    vendorName: "",
    vendorMobile: "",
    vendorEmail: "",
    vendorgst: "",
    subtotal: 0,
    toatalAmount: 0,
    product: [],
    tax: [],
    extrafees: [],
    user_id: token?._id,
    To: "",
    Subject: "",
    billTaxType: "",
    invoiceTemplateType: "",
    colorTemplateType: "",
    vendorId: "",
    purchaseEntryNo: "",
    nontaxableTotal: "",
    billModule: "",
    paymentType: selectedPayment,
    cashAmount: CashAmount,
    balanceAmount: balanceAmount,
    receivedAmount: receivedAmount,
    paymentStatus: "PAID",
    totalInWords: "",
    gstValue: "",
    isSendEmail: true,
    isvendorMobile: true,
  } as any);

  useEffect(() => {
    dispatch(fontFamilyGet(token?._id) as any).then((res: any) => {
      if (res?.type === "FONTFAMILY_GET_SUCCESS") {
        let data = res?.payload?.find(
          (value: any) => value?.defaultInfo === true,
        );
        if (data) {
          setSelectedFont(data?.fontfamily);
        } else {
          setSelectedFont("poppins");
        }
      }
    });
  }, []);

  const [currencySymbol, setcurrencySymbol] = useState("₹");


  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      balanceAmount: balanceAmount ? balanceAmount : 0,
      receivedAmount: receivedAmount ? Number(receivedAmount) : 0
    }));
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      invoicedate: formData?.invoicedate ? formData?.invoicedate : formatDate,
    }));
  }, [balanceAmount, receivedAmount]);

  useEffect(() => {
    dispatch(currencyGet(token?._id) as any).then((res: any) => {
      if (res) {
        let data = res.payload.find((value: any) => value.defaultInfo === true);
        if (data?.currencySymbol) {
          setcurrencySymbol(data.currencySymbol);
        } else {
          setcurrencySymbol("₹");
        }
      }
    });
  }, []);


  // ****************************************Image section***************************************************


  const [vendorErrors, setVendorErrors] = useState<{
    vendorName?: string;
    vendorMobile?: string;
    purchaseEntryNo?: string;
    paymentType?: string;
  }>({});

  const [sendEmailErrors, setSendEmailErrors] = useState<{ To?: string }>({});

  const validateProdForm = () => {

    if (formData.vendorName?.trim() === "") {
      vendorErrors["vendorName"] = "Vendor Name is Requried  ";
    }
    if (formData.purchaseEntryNo?.trim() === "") {
      vendorErrors["purchaseEntryNo"] = "Purchase Entry Number is Requried  ";
    }
    if (formData.paymentType?.trim() === "") {
      vendorErrors["paymentType"] = "Payment Type is Requried  ";
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value.trimStart();
    if (name == "paymentType") {
      setSelectedPayment(e.target.value);
      setbalanceAmount(0);
      if (formData.product.length === 0) {
        setShowConfirmation(true)
      }

    }
    if (name == "cashAmount") {

      let cashvalue = e.target.value;
      let balance = formData.toatalAmount - Number(cashvalue);
      setbalanceAmount(balance);
      setCashAmount(cashvalue);
      setreceivedAmount(cashvalue)
    }
    if (value == 'CASH') {
      setCashAmount(formData.toatalAmount);
      setreceivedAmount(formData.toatalAmount)
      setbalanceAmount(0.00)

    }
    setErrorEmail(null);
    setSendEmailErrors({ ...sendEmailErrors, [e.target.name]: "" });
    setVendorErrors({ ...vendorErrors, [e.target.name]: "" });
    let isSendEmail = true;
    let isvendorMobile = true;
    if (e.target.name === "To" && value) {
      isSendEmail = emailRegex.test(value);
    }

    if (e.target.name === "vendorMobile" && value) {
      isvendorMobile = value.length === 10;
    }
    // Validate stock quantity


    setFormData({
      ...formData,
      [e.target.name]: newValue,
      isvendorMobile:
        e.target.name === "vendorMobile"
          ? isvendorMobile
          : formData.isvendorMobile,
      isSendEmail: e.target.name === "To" ? isSendEmail : formData.isSendEmail,
    });
  };
  const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);
  const handleChangeForInvoiceNo = (e: any) => {
    const selectedInvoiceNo = e.target.value;
    setSelectId(selectedInvoiceNo)
    const selectedVendor = purchaseList.find(vendor => vendor.invoiceNo === selectedInvoiceNo);
    if (selectedVendor) {
      const updatedProductRows = selectedVendor.product.map((product: any) => ({

        prodcut: product.prodcut,
        hsn: product.hsn || "",
        rate: product.rate || "",
        qty: (product.qty) || "",
        amount: product.amount || "",
        igst: product.prodigst || "",
        cgst: product.prodcgst || "",
        sgst: product.prodsgst || "",
        gst: product.gst || "",
        discount: product.discount || "",
      }));

      const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
        Object.values(row).some((value) => !value)
      );

      if (containsEmptyRowsinExistProduct) {
        const nonEmptyRows = newExistProduct.filter((row: any) =>
          Object.values(row).some(Boolean)
        );
        setNewexistProduct(nonEmptyRows);
      }
      setNewexistProduct((prev: any) => [
        ...prev,
        ...updatedProductRows.map((row: any) => ({
          exist: Number(row.qty),
          originalValue: Number(row.qty)
        }))
      ]);

      const nontaxableItems = selectedVendor.product

      const nontaxableTotal = nontaxableItems.reduce((total: number, item: any) => {
        const rate = item.rate || 0;
        const qty = item.qty || 0;
        return total + rate * qty; // Multiply rate and qty and add to the total
      }, 0);
      const combinedTaxRow = selectedVendor.tax.reduce((acc: any, taxRow: any) => {
        return {
          cgstStatus: taxRow.cgstStatus ?? acc.cgstStatus,
          cgstPercentage: taxRow.cgstStatus === true ? Number(taxRow?.taxPersent) : acc.cgstPercentage || 0,

          sgstStatus: taxRow.sgstStatus ?? acc.sgstStatus,
          sgstPercentage: taxRow.sgstStatus === true ? Number(taxRow?.taxPersent) : acc.sgstPercentage || 0,

          igstStatus: taxRow.igstStatus ?? acc.igstStatus,
          igstPercentage: taxRow.igstStatus === true ? Number(taxRow?.taxPersent) : acc.igstPercentage || 0,

          othersStatus: taxRow.othersStatus ?? acc.othersStatus,
          othersPercentage: taxRow.othersStatus === true ? Number(taxRow?.taxPersent) : acc.othersPercentage || 0,

          discountStatus: taxRow.discountStatus ?? acc.discountStatus,
          discountPercentage: taxRow.discountStatus === true ? Number(taxRow?.taxPersent) : acc.discountPercentage || 0,

          extraFeesStatus: taxRow.extraFeesStatus ?? acc.extraFeesStatus,
          extraFees: taxRow.extraFeesStatus === true ? Number(taxRow?.taxPersent) : acc.extraFees || 0,
        };
      }, {});

      // Set the combined object at index [0]
      setTaxAllList([combinedTaxRow]);
      setProductRows(updatedProductRows)
      setVendorErrors({ ...vendorErrors, [e.target.name]: "" });
      let finalErrors: any = [];
      let values = updatedProductRows?.map((item: any, index: any) => {
        let object: any = {};
        object.prodcut = item.prodcut ? true : false;
        object.rate = item.rate ? true : false;
        object.qty = item.qty ? true : false;
        finalErrors.push(object);
        return object;
      });

      setProdcutErrors(values);
      let billtype = selectedVendor.billTaxType
      setFormData((formData: any) => ({
        ...formData,
        billTaxType: billtype,
        tax: selectedVendor.tax,
        subtotal: selectedVendor.subtotal,
        nontaxableTotal: nontaxableTotal,
        invoiceDate: selectedVendor.invoicedate,
        product: selectedVendor.product,
        toatalAmount: selectedVendor.toatalAmount,
        purchaseEntryNo: selectedInvoiceNo,
        invoiceTemplateType: selectedVendor.invoiceTemplateType ? selectedVendor.invoiceTemplateType : "",
        colorTemplateType: selectedVendor.template ? selectedVendor.template : "",
        gstValue: selectedVendor.gstValue ? selectedVendor.gstValue : ""
      }))

    }

  };

  // ***********************************Start***********************************
  const fetchData = () => {
    dispatch(InternalGetCount() as any).then((data: any) => {
      if (data) {

        let newCountValue = data?.payload?.purchaseReturnCount;
        let match = newCountValue?.match(/\d{4}$/);
        let result = "";
        if (match) {
          let value = match[0];
          result = (parseInt(value) + 1).toString().padStart(4, "0");
        }
        let datevalue = moment(new Date()).format("YYYY-MM-DD");
        const [year, month] = datevalue.split("-");
        let invoice =
          `${"PRTN-"}` +
          String(`${month}/`) +
          String(
            `${year}/`,
          ) +
          String(result,
          );

        setFormData({
          ...formData,
          invoiceNo: invoice,
          paymentInReceiptNo: data?.payload?.paymentInReceiptCount,
        });
      }
    });
  };


  const [confirmDownload, setConfirmDownload] = useState(false);
  const [productError, setProductError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const [prodcutRows, setProductRows] = useState<any>([
    {
      prodcut: "",
      hsn: "",
      rate: "",
      qty: "",
      igst: "",
      cgst: "",
      sgst: "",
      amount: 0,
      igstAmount: "",
      cgstAmount: "",
      sgstAmount: "",
    },
  ] as any);

  const [prodcutErrors, setProdcutErrors] = useState<any>([
    { prodcut: false, units: false, rate: false, qty: false },
  ] as any);

  const updateProductErrors = (index: any, fieldName: any, value: any) => {
    const newErrors = [...prodcutErrors];
    newErrors[index][fieldName] = value ? true : false;
    setProdcutErrors(newErrors);
  };


  const addRow = () => {
    setProductRows([
      ...prodcutRows,
      { prodcut: "", hsn: "", rate: "", qty: "", amount: 0, productName: "" },
    ]);



    setProdcutErrors([
      ...prodcutErrors,
      {
        prodcut: productError,
        rate: productError,
        qty: productError,
      },
    ]);


  };

  const getProductCal = (values: any) => {
    let products = [...values.product];
    let finalProduct = products?.map((item: any) => {
      item.prodcgst = TaxAllList[0]?.cgstPercentage || 0;
      item.prodsgst = TaxAllList[0]?.sgstPercentage || 0;
      item.prodigst = TaxAllList[0]?.igstPercentage || 0;
      item.prodvat = TaxAllList[0]?.vatPercentage || 0;
      item.prodothers = TaxAllList[0]?.othersPercentage || 0;
      item.totalgst =
        parseFloat(TaxAllList[0]?.cgstPercentage || 0) +
        parseFloat(TaxAllList[0]?.sgstPercentage || 0) +
        parseFloat(TaxAllList[0]?.igstPercentage || 0) +
        parseFloat(TaxAllList[0]?.vatPercentage || 0) +
        parseFloat(TaxAllList[0]?.othersPercentage || 0);
      item.grandTotal =
        ((parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
          parseFloat(item.rate)) *
        parseFloat(item.qty);
      item.total =
        (parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
        parseFloat(item.rate);
      item.creation_date = new Date();
      return item;
    });
    return finalProduct;
  };

  const fetchDownload = (data: any) => {
    formData?.product.forEach((item: any) => {
      item.amount = Number(item.amount).toFixed(2);
    });
    const totalAmount = formData.toatalAmount;
    const roundedtotalAmount = Math.round(totalAmount);
    const totalAmountInWords = Number.isFinite(roundedtotalAmount)
      ? toWords(roundedtotalAmount)
      : "Invalid amount";
    const totalAmountInWordsPrint = totalAmountInWords + " Only";
    formData.totalInWords = totalAmountInWordsPrint;

    const queryParams = new URLSearchParams();
    if (userdata?.template) queryParams.append("param5", userdata?.template);
    queryParams.append("param6", selectedFont);
    queryParams.append("param7", currencySymbol);
    if (userdata?.invoiceType) queryParams.append("param8", userdata?.invoiceType);
    formData.alt = userdata?.template;
    const url = `/api/invoice_pdf/download?${queryParams.toString()}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: encryptData(JSON.stringify(formData)),
      // body: JSON.stringify(encryptData(formData)),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link: any = document.createElement("a");
        link.href = url;
        const formattedDate = moment().format("YYYY-MM-DD");
        link.setAttribute(
          "download",
          `invoice_${formData.vendorName}_${formattedDate}.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setProductError(false);

        setConfirmDownload(false);
      })
      .catch((error) => console.error("Error downloading PDF:", error));

  };

  const downloadInvoice = () => {
    fetchDownload(formData);
  };
  const [invoiceUpdateNewId, setInvoiceUpdateNewId] = useState(null as any)
  const saveAndDownloadInvoice = () => {

    if (!id && !invoiceUpdateId) {
      if (formData.billTaxType != "Product Wise") {
        formData.product = getProductCal(formData);

      }
      // formData.billTaxType = userdata?.taxType ? userdata?.taxType : ""
      formData.invoiceTemplateType = userdata?.invoiceType ? userdata?.invoiceType : ""
      formData.colorTemplateType = userdata?.template ? userdata?.template : ""


      dispatch(purchaseReturnPost(formData) as any)
        .then((response: any) => {
          setInvoiceUpdateNewId(response?.payload?.invoice_id)
          formData?.product.forEach((item: any) => {
            item.amount = Number(item.amount).toFixed(2);
          });
          if (response?.payload?.invoice_id) {
            const queryParams = new URLSearchParams();
            if (userdata?.template) {
              queryParams.append("param5", userdata?.template);
            }
            queryParams.append("param6", selectedFont);
            queryParams.append("param7", currencySymbol);
            if (userdata?.invoiceType) {
              queryParams.append("param8", userdata?.invoiceType);
            }

            const totalAmount = formData.toatalAmount;
            const roundedtotalAmount = Math.round(totalAmount);
            const totalAmountInWords = Number.isFinite(roundedtotalAmount)
              ? toWords(roundedtotalAmount)
              : "Invalid amount";
            const totalAmountInWordsPrint = totalAmountInWords + " Only.";
            formData.totalInWords = totalAmountInWordsPrint;
            // Construct the URL with the query parameters
            const url = `/api/purchaseReturn_pdf/${response?.payload?.invoice_id
              }?${queryParams.toString()}`;
            fetch(url, {
              method: "GET",
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.blob();
              })
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link: any = document.createElement("a");
                link.href = url;
                const formattedDate = moment().format("YYYY-MM-DD");
                link.setAttribute(
                  "download",
                  `invoice_${formData.vendorName}_${formattedDate}.pdf`,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setProductError(false);
                setInvoiceUpdateId(response?.payload?.invoice_id);
                setConfirmDownload(false);
              })
              .catch((error) => console.error("Error downloading PDF:", error));
          }
        });

    } else if (id || invoiceUpdateId) {
      let invoiceId = id ? id : invoiceUpdateId;
      if (formData.billTaxType != "Product Wise") {
        formData.product = getProductCal(formData);

      }
      formData?.product.forEach((item: any) => {
        item.amount = Number(item.amount).toFixed(2);
      });
      dispatch(PchaseReturnEdit(invoiceId, formData) as any)
        .then(
          (response: any) => {
            const queryParams = new URLSearchParams();
            if (formData.colorTemplateType) {
              queryParams.append("param5", formData.colorTemplateType);
            }
            queryParams.append("param6", selectedFont);
            queryParams.append("param7", currencySymbol);
            if (formData.invoiceTemplateType) {
              queryParams.append("param8", formData.invoiceTemplateType);
            }

            const totalAmount = formData.toatalAmount;
            const roundedtotalAmount = Math.round(totalAmount);
            const totalAmountInWords = Number.isFinite(roundedtotalAmount)
              ? toWords(roundedtotalAmount)
              : "Invalid amount";
            const totalAmountInWordsPrint = totalAmountInWords + " Only";
            formData.totalInWords = totalAmountInWordsPrint;

            // Construct the URL with the query parameters

            const url = `/api/purchaseReturn_pdf/${invoiceId}?${queryParams.toString()}`;
            fetch(url, {
              method: "GET",
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.blob();
              })
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link: any = document.createElement("a");
                link.href = url;
                const formattedDate = moment().format("YYYY-MM-DD");
                link.setAttribute(
                  "download",
                  `invoice_${formData.vendorName}_${formattedDate}.pdf`,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setProductError(false);
                setConfirmDownload(false);
              })
              .catch((error) => console.error("Error downloading PDF:", error));
          },
        );
    }
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let finalErrors = [...prodcutErrors];
    var ProductValidation = prodcutRows?.some(
      (item: any) => !item.prodcut || !item.rate || !item.qty,
    );

    prodcutRows.forEach((item: any, index: any) => {
      finalErrors[index].prodcut = item.prodcut ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setProdcutErrors(finalErrors);


    if (
      !ProductValidation &&
      formData.vendorName &&
      formData.vendorMobile &&
      formData.paymentType &&
      !formData.errors?.vendorMobileErrorMessage
    ) {
      setConfirmDownload(true);
    } else {
      setProductError(true);
      validateProdForm();
      setConfirmDownload(false);
    }
  };

  const [openmail, setOpenMail] = useState(false as any);
  const handleSectionOpen = (e: any) => {
    let finalErrors = [...prodcutErrors];
    var ProductValidation = prodcutRows?.some(
      (item: any) => !item.prodcut || !item.rate || !item.qty,
    );

    prodcutRows.forEach((item: any, index: any) => {
      finalErrors[index].prodcut = item.prodcut ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setProdcutErrors(finalErrors);

    if (
      !ProductValidation &&

      formData.vendorName &&
      formData.vendorMobile &&
      !formData.errors?.vendorMobileErrorMessage
    ) {
      setOpenMail(true);
    } else {
      setProductError(true);

      validateProdForm();
    }
  };

  const [errorEmail, setErrorEmail] = useState(null as any);
  const handleSendEmail = () => {
    if (formData.To?.trim() !== "" && emailRegex.test(formData.To)) {
      const emailViewPdf = {
        fromEmail: formData.From,
        toEmail: formData.To,
        subject: formData.Subject,
        message: formData.Message,
        vendorName: formData.vendorName,
      };
      if (!id) {
        if (invoiceUpdateNewId) {
          dispatch(PchaseReturnEdit(invoiceUpdateNewId, formData) as any).then(
            (response: any) => {
              const queryParams = new URLSearchParams();
              queryParams.append("param5", selectedFont);
              queryParams.append("param6", currencySymbol);
              if (userdata?.template) {
                queryParams.append("param7", userdata?.template);
              }
              if (userdata?.invoiceType) {
                queryParams.append("param8", userdata?.invoiceType);
              }

              fetch(
                `/api/purchaseReturn_send_email_pdf/${invoiceUpdateNewId
                }?${queryParams.toString()}`,
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.blob();
                })
                .then((blob) => {
                  api.post(`/api/purchase_Return_upload`, emailViewPdf)
                    .then((pdfResp: any) => {
                      console.log("Blob uploaded successfully:", pdfResp);
                      if (pdfResp.data.message) {
                        toast.success("Email Send Successfully", {
                          position: toast.POSITION.BOTTOM_CENTER,
                          autoClose: 800,
                        });
                      }


                    })
                    .catch((error: any) => {
                      console.error("Error uploading blob:", error);
                    });
                })
                .catch((error) => console.error("Error downloading PDF:", error));
            })
        }
        else {
          dispatch(purchaseReturnPost(formData) as any).then((response: any) => {
            setInvoiceUpdateNewId(response?.payload?.invoice_id)
            fetchData2()
            formData?.product.forEach((item: any) => {
              item.amount = Number(item.amount).toFixed(2);
              item.rate = Number(item.rate).toFixed(2);
            });
            const queryParams = new URLSearchParams();
            queryParams.append("param5", selectedFont);
            queryParams.append("param6", currencySymbol);
            if (userdata?.template) {
              queryParams.append("param7", userdata?.template);
            }
            if (userdata?.invoiceType) {
              queryParams.append("param8", userdata?.invoiceType);
            }
            if (response?.payload?.invoice_id) {

              const totalAmount = formData.toatalAmount;
              const roundedtotalAmount = Math.round(totalAmount);
              const totalAmountInWords = Number.isFinite(roundedtotalAmount)
                ? toWords(roundedtotalAmount)
                : "Invalid amount";
              const totalAmountInWordsPrint = totalAmountInWords + " Only.";
              formData.totalInWords = totalAmountInWordsPrint;

              fetch(
                `/api/purchaseReturn_send_email_pdf/${response?.payload?.invoice_id
                }?${queryParams.toString()}`,
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.blob();
                })
                .then((blob) => {
                  api.post(`/api/purchase_Return_upload`, emailViewPdf)
                    .then((pdfResp: any) => {
                      console.log("Blob uploaded successfully:", pdfResp);
                      if (pdfResp.data.message) {
                        toast.success("Email Send Successfully", {
                          position: toast.POSITION.BOTTOM_CENTER,
                          autoClose: 800,
                        });
                      }


                    })
                    .catch((error: any) => {
                      console.error("Error uploading blob:", error);
                    });
                })
                .catch((error) => console.error("Error downloading PDF:", error));
            }
          });
        }



      }
      else if (id || invoiceUpdateId) {
        let invoiceId = id ? id : invoiceUpdateId;
        formData.product = getProductCal(formData);
        formData?.product.forEach((item: any) => {
          item.amount = Number(item.amount).toFixed(2);
        });

        dispatch(InternalInvoiceUpdate(invoiceId, formData) as any).then(
          (response: any) => {
            const queryParams = new URLSearchParams();
            if (formData.colorTemplateType) {
              queryParams.append("param5", formData.colorTemplateType);
            }
            queryParams.append("param6", selectedFont);
            queryParams.append("param7", currencySymbol);
            if (formData.invoiceTemplateType) {
              queryParams.append("param8", formData.invoiceTemplateType);
            }

            const totalAmount = formData.toatalAmount;
            const roundedtotalAmount = Math.round(totalAmount);
            const totalAmountInWords = Number.isFinite(roundedtotalAmount)
              ? toWords(roundedtotalAmount)
              : "Invalid amount";
            const totalAmountInWordsPrint = totalAmountInWords + " Only";
            formData.totalInWords = totalAmountInWordsPrint;

            // Construct the URL with the query parameters

            fetch(
              `/api/purchaseReturn_send_email_pdf/${invoiceId
              }?${queryParams.toString()}`,
            )
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.blob();
              })
              .then((blob) => {
                api.post(`/api/purchase_Return_upload`, emailViewPdf)
                  .then((pdfResp: any) => {
                    console.log("Blob uploaded successfully:", pdfResp);
                    if (pdfResp.data.message) {
                      toast.success("Email Send Successfully", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                      });
                    }


                  })
                  .catch((error: any) => {
                    console.error("Error uploading blob:", error);
                  });
              })
              .catch((error) => console.error("Error downloading PDF:", error));
          },
        );


      }

      setOpenMail(false);
      setErrorEmail(null);
      setOpenView(false);
      setFormData({
        ...formData,
        To: "",
        Subject: "",
        Message: "",
        errors: { ...formData.errors, ToErrorMessage: "" },
      });
    } else {
      if (formData.To?.trim() === "") {
        sendEmailErrors["To"] = "Email is requried  ";
        setErrorEmail(sendEmailErrors);
      }
    }
  };

  // *********************************************EMail Sctions End**************************************
  const [editValue, setEditValue] = useState(false as any);

  useEffect(() => {

    if (!id) {
      fetchData();
      // formData.billTaxType = userdata?.taxType
      formData.invoiceTemplateType = userdata?.invoiceType
      formData.colorTemplateType = userdata?.template
    } else {
      dispatch(purchaseInternalFindOne(id) as any).then((response: any) => {

        let accountSettings = JSON.parse(response?.payload.app_settings);
        setTaxAllList(accountSettings);
        let invoiceDetails = JSON.parse(response?.payload.products);
        setselectedVendorId(invoiceDetails?.vendorID)
        let products = invoiceDetails.product;

        if (id) {
          setEditValue(true);
          let newTaxStatus: any = [];
          let newTaxArr: any = [];
          for (let o in accountSettings[0]) {
            if (accountSettings[0][o] === true) {
              newTaxStatus.push(o);
            }
          }
          let finalTotal = invoiceDetails.subtotal;
          newTaxStatus.forEach((element: string) => {
            if (element === "cgstStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].cgstPercentage)) /
                100;
              newTaxArr.push({
                taxName: "CGST",
                cgstStatus: accountSettings[0].cgstStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].cgstPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "sgstStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].sgstPercentage)) /
                100;
              newTaxArr.push({
                taxName: "SGST",
                cgstStatus: accountSettings[0].sgstStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].sgstPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "extraFeesStatus") {
              newTaxArr.push({
                taxName: "Extra Fees",
                cgstStatus: accountSettings[0].extraFeesStatus,
                taxAmount: accountSettings[0].extraFees,
                taxPersent: accountSettings[0].extraFees,
              });
              finalTotal =
                Number(finalTotal) + Number(accountSettings[0].extraFees);
            } else if (element === "igstStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].igstPercentage)) /
                100;
              newTaxArr.push({
                taxName: "IGST",
                cgstStatus: accountSettings[0].igstStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].igstPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "vatStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].vatPercentage)) /
                100;
              newTaxArr.push({
                taxName: "VAT",
                cgstStatus: accountSettings[0].vatStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].vatPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "othersStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].othersPercentage)) /
                100;
              newTaxArr.push({
                taxName: "OTHERS",
                cgstStatus: accountSettings[0].othersStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].othersPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            }
          });
          if (newTaxStatus.includes("discountStatus")) {
            let taxAmt =
              (finalTotal * Number(accountSettings[0].discountPercentage)) /
              100;
            newTaxArr.push({
              taxName: "Discount",
              cgstStatus: accountSettings[0].discountStatus,
              taxAmount: taxAmt.toFixed(2).toString(),
              taxPersent: accountSettings[0].discountPercentage,
            });
            finalTotal -= taxAmt;
          }
          let isSendEmail = invoiceDetails.isSendEmail ? true : true;
          let vendormobile = invoiceDetails.vendorMobile ? true : true;
          let billTaxType = invoiceDetails.billTaxType;
          let invoiceTemplateType = invoiceDetails.invoiceTemplateType;
          let colorTemplateType = invoiceDetails.colorTemplateType;
          let subtotal = invoiceDetails.subtotal;
          // let termsanddconvalue = invoiceDetails.termsanddcon
          setFormData({
            ...invoiceDetails,
            tax: [...newTaxArr],
            toatalAmount: finalTotal,
            isSendEmail: isSendEmail,
            isvendorMobile: vendormobile,
            billTaxType: billTaxType,
            invoiceTemplateType: invoiceTemplateType,
            colorTemplateType: colorTemplateType,
            subtotal: Number(subtotal)
            // termsanddcon: termsanddconvalue
          });
          setSelectId(invoiceDetails?.invoiceNo)
          setSelectedPayment(invoiceDetails.paymentType)
          let finalErrors: any = [];


          let values = products?.map((item: any, index: any) => {
            let object: any = {};
            object.prodcut = item.prodcut ? true : false;
            object.rate = item.rate ? true : false;
            object.qty = item.qty ? true : false;

            finalErrors.push(object);

            return object; // Return the constructed object
          });
          setProdcutErrors(values);
          setIsToggled(!isToggled)
        }
      });
    }


  }, [id]);

  const closeModal = () => {
    setErrorEmail(null);
    setOpenView(false);
    setOpenMail(false);
    setFormData({
      ...formData,
      To: "",
      Subject: "",
      Message: "",
      errors: { ...formData.errors, ToErrorMessage: "" },
    });
  };
  const closeModalexisting = () => {
    setIsModalOpen(false);
  };
  const [viewOpen, setOpenView] = useState(false);
  const cancelButtonRef = useRef(null);
  const componentRef = useRef(null);

  // const [preViewdDis, setPreViewDis] = useState(true)

  const openChoosePreview = () => {

    let finalErrors = [...prodcutErrors];
    var ProductValidation = prodcutRows?.some(
      (item: any) => !item.prodcut || !item.rate || !item.qty,
    );

    prodcutRows.forEach((item: any, index: any) => {
      finalErrors[index].prodcut = item.prodcut ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setProdcutErrors(finalErrors);


    if (
      !ProductValidation &&
      formData.vendorName &&
      formData.vendorMobile &&
      !formData.errors?.vendorMobileErrorMessage
    ) {
      setOpenView(true);
    } else {
      setProductError(true);
      validateProdForm();
    }
  };
  const handlePrint = useReactToPrint({
    content: () => getPrintContent(),
    documentTitle: "Invoice Letter",
    onAfterPrint: () => {
      console.log("Print completed!");
    },
  });

  const getPrintContent = (): any => {

    let finalErrors = [...prodcutErrors];
    var ProductValidation = prodcutRows?.some(
      (item: any) => !item.prodcut || !item.rate || !item.qty,
    );

    prodcutRows.forEach((item: any, index: any) => {
      finalErrors[index].prodcut = item.prodcut ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setProdcutErrors(finalErrors);


    if (
      !ProductValidation &&
      formData.vendorName &&
      formData.vendorMobile &&
      !formData.errors?.vendorMobileErrorMessage
    ) {
      return componentRef.current;
    } else {
      setProductError(true);
      validateProdForm();
    }
  };
  const handleCancel = () => {
    setConfirmDownload(false);
    // setShowConfirmation(false)
  };
  const handleCancelForPaymentType = () => {
    setShowConfirmation(false)
    setSelectedPayment('')
  };
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);

  const fetchDataForPurchase = () => {
    dispatch(PurchaseFindAll() as any).then((response: any) => {
      if (response && response.payload) {
        setPurchaseList(response.payload);
      }
    });
    dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
    });
  };

  useEffect(() => {

    if (userId?.token?._id) {
      fetchData2();
      fetchDataForPurchase();
    }
  }, []);

  const fetchData2 = () => {

  };
  // ***********************************search******************************
  const handleConfirm = () => {
    setIsModalOpen(true)
  };
  const handleSelect = (data: any) => {
    vendorErrors.vendorName = '';
    setselectedVendorId(data._id?.$oid);
    setFormData({
      ...formData,
      vendorName: data.vendor_name,
      vendorID: data._id?.$oid,
      vendorAddress: data.vendor_address,
      vendorCity: data.city,
      vendorState: data.state,
      vendorPostalCode: data.postal_code,
      vendorMobile: data.vendor_mobile,
      vendorEmail: data.vendor_email,
      vendorPan: data.pan_no,
      vendorgst: data.gst,
      isClientGst: true,
      isClientPan: true,
      isClientEmail: true,
      isvendorPostalCode: true,
      isvendorMobile: true,
      errors: { ...formData.errors, vendorgstErrorMessage: '', vendorEmailErrorMessage: '', vendorPanErrorMessage: '', vendorMobileErrorMessage: '', vendorPostalCodeErrorMessage: "" }
    });

    setIsModalOpen(false);


  };

  const invoiceNo = purchaseList.filter(vendor => vendor.vendorID === selectedVendorId && vendor.purchaseReturnStatus === "").map(vendor => vendor.invoiceNo);
  return (
    <>
      <div>
        {(userdata?.taxType == "Bill Wise" && formData.billTaxType == "Bill Wise") ? (
          <>

            <div style={{ display: "none" }}>
              <Print Invoice={formData} componentRef={componentRef} />
            </div>
          </>
        ) : (
          <>

            <div style={{ display: "none" }}>
              <PrintProductwise
                Invoice={formData}
                componentRef={componentRef}
              />
            </div>

          </>
        )}

      </div>
      <div className="flex flex-wrap  ">
        <div className="w-full lg:w-10/12 pl-4 pr-4 sm:pl-4 sm:pr-0 mb-4 sm:mb-1 margin  items-start bg-[white] min-h-screen pb-5">
          <form>
            <div className="bg-[white] sticky z-10 top-0 left-0 right-0 pb-2 ">
              <div className="justify-center flex flex-row relative">

                <input
                  className={` rounded-[7px] text-center xs:w-1/4 mt-2 focus:outline-none lg:text-3xl xl:text-3xl 2xl:text-3xl md:text-3xl text-base`}
                  autoComplete="nope"
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                  defaultValue={formData.invoicehead}
                  type="text"
                  ref={ref}
                  id="input_focus"
                  aria-label="Search"
                  name="invoicehead"
                />
                {/* </h1> */}

              </div>
            </div>

            <div className={"flex flex-col sm:flex-row pt-3 pr-4 sm:pr-8 "}>
              <div className="  flex-1 md:mx-2 mt-2 md:mt-[2px] ">
                <div className="flex flex-col md:flex-row justify-between items-left">
                  <div
                    className="flex flex-1 items-center whitespace-nowrap justify-start"
                    style={{
                      color: "#008065",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Vendor Details
                  </div>
                  <div className="flex flex-1 items-center justify-center md:justify-end mr-2">
                    <ClientPlusIcon className="w-4 h-4" />
                    <Button onClick={handleConfirm}>
                      <span
                        style={{
                          color: "#008065",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                        className="text-[#008065] ml-1"
                      >
                        EXISTING
                      </span>
                    </Button>
                  </div>
                  {isModalOpen && (
                    <VendorModel closeModal={closeModalexisting} handleSelect={handleSelect} />
                  )}
                </div>
                <div className="flex flex-row justify-between items-center mb-2">
                  <div
                    className="flex-1  required"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    <span>Name</span>
                  </div>
                  <div className="font-title flex-1 text-left">
                    <input
                      autoComplete="nope"
                      placeholder="vendor Name"
                      className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: vendorErrors.vendorName ? "red" : "#008065",
                      }}
                      name='clientName'
                      value={formData.vendorName}
                      onChange={(e) => handleChange(e)}
                      readOnly
                    />
                    {vendorErrors.vendorName && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {vendorErrors.vendorName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center mb-2">
                  <div
                    className=" flex-1 mb-2"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Mobile
                  </div>
                  <div className="font-title flex-1 text-left">
                    <input
                      type="text"
                      autoComplete="nope"
                      placeholder="Mobile Number"
                      className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: "#008065",
                      }}
                      maxLength={10}
                      name="vendorMobile"
                      value={formData.vendorMobile}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (re.test(e.target.value) || (e.target.value == "")) {
                          handleChange(e)
                        }
                      }}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center mb-2">
                  <div
                    className="flex-1"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Email
                  </div>
                  <div className="font-title flex-1 text-left ">
                    <input
                      autoComplete="nope"
                      placeholder="Email (Optional)"
                      className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: "#008065",
                      }}
                      name='vendorEmail'
                      value={formData.vendorEmail}
                      onChange={(e) => handleChange(e)}
                      readOnly
                    />
                  </div>
                </div>

              </div >
              <div className="  flex-1 md:mx-2">
                <div>
                  <div className="sm:flex-1 mt-7 p-0 sm:p-2 ">
                    <div className="flex flex-row justify-between items-center mb-2">
                      <div
                        className="flex-1"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <span>Purchase Return No</span>
                      </div>
                      <div className="font-title flex-1 text-left">
                        <input
                          autoComplete="nope"
                          className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: "#008065",
                          }}
                          name="invoiceNo"
                          value={formData.invoiceNo}
                          onChange={(e: any) => handleChange(e)}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-2">
                      <div
                        className=" flex-1 mb-2"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >

                        Purchase Return Date
                      </div>
                      <div className="font-title flex-1 text-left">
                        <DatePicker
                          // style={{ fill: "red !important" }}

                          value={formData?.purchaseReturnDate}
                          onChange={(date) => {
                            setPurchaseReturnDate(date);
                            setFormData({
                              ...formData,
                              purchaseReturnDate: moment(date).format("DD/MM/YYYY"),
                            });
                          }}
                          dateFormat={"dd/MM/yyyy"}
                          wrapperClassName="return-datepicker"
                          scrollableYearDropdown
                          customInput={<CustomInputWithIcon />}
                          showPopperArrow={false}

                        />

                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-2">
                      <div
                        className="flex-1 required"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <span>Purchase Entry No</span>
                      </div>
                      <div className="font-title flex-1 text-left ">
                        <select
                          autoComplete="nope"
                          className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: vendorErrors.purchaseEntryNo ? "red" : "#008065",
                          }}
                          name="purchaseEntryNo"
                          value={invoiceSelectId ? invoiceSelectId : ""}
                          onChange={(e) => handleChangeForInvoiceNo(e)}
                          disabled={id ? true : false}
                        >
                          <option value="" hidden>{id ? formData.purchaseEntryNo : "Select an Invoice No"}</option>
                          {invoiceNo.map((invoiceNo, index) => (
                            <option key={index} value={invoiceNo}>
                              {invoiceNo}
                            </option>
                          ))}
                        </select>
                        {vendorErrors.purchaseEntryNo && (
                          <p
                            style={{
                              fontFamily: "poppins",
                              fontWeight: 700,
                              color: "#ff0000",
                            }}
                            className="text-red-400 text-xs"
                          >
                            {vendorErrors.purchaseEntryNo}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div >

            <hr className="mr-8 ml-4 mt-2" style={{ borderColor: "#008065" }} />
            <div className="py-2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4">
              {(formData.billTaxType == "Product Wise") ? (
                <>
                  <PurchaseNewReturnProducts
                    setCashAmount={setCashAmount}
                    setSelectedPayment={setSelectedPayment}
                    setbalanceAmount={setbalanceAmount}
                    newExistProduct={newExistProduct}
                    setNewexistProduct={setNewexistProduct}
                    formData={formData}
                    setFormData={setFormData}
                    TaxAllList={TaxAllList}
                    paramsId={id}
                    updateProductErrors={updateProductErrors}
                    prodcutErrors={prodcutErrors}
                    prodcutRows={prodcutRows}
                    setProductRows={setProductRows}
                    productError={productError}
                    addRow={addRow}
                    setProductError={setProductError}
                    setProdcutErrors={setProdcutErrors}
                    editValue={editValue}
                  />


                </>
              ) : (
                <>
                  <PurchaseReturnProduct
                    setCashAmount={setCashAmount}
                    setSelectedPayment={setSelectedPayment}
                    setbalanceAmount={setbalanceAmount}
                    newExistProduct={newExistProduct}
                    formData={formData}
                    setFormData={setFormData}
                    TaxAllList={TaxAllList}
                    paramsId={id}
                    updateProductErrors={updateProductErrors}
                    prodcutErrors={prodcutErrors}
                    prodcutRows={prodcutRows}
                    setProductRows={setProductRows}
                    productError={productError}
                    addRow={addRow}
                    setProductError={setProductError}
                    setProdcutErrors={setProdcutErrors}
                    editValue={editValue}
                    setNewexistProduct={setNewexistProduct}
                  />

                </>
              )}
              <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 items-center"}>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                  SUBTOTAL
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                  <NumberFormat value={formData.subtotal ? formData.subtotal : 0} />
                </div>
              </div>

              {formData.billTaxType == "Product Wise" ? (
                <></>
              ) : (
                <GstSection TaxAllList={TaxAllList} formData={formData} />
              )}

              <div className={"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"}>
                <div className={"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"}>
                  <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"}>
                    TOTAL
                  </div>
                  <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"}>
                    <NumberFormat value={formData.toatalAmount} />
                  </div>
                </div>
              </div>
              <div
                className={
                  "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                }
              >
                <div
                  className={
                    "w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
                  }
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                    }
                  >
                    PAYMENT TYPE
                  </div>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "font-title text-lg w-1/2  text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                    }
                  >
                    <select
                      name="paymentType"
                      id="paymentType"
                      className={`focus:outline-none rounded-[7px] w-full px-2 h-9 lg:w-full$ border-2 border-[#008065]`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "13px",
                        fontWeight: 400,
                        borderColor: vendorErrors.paymentType ? "red" : "#008065",
                      }}
                      value={selectedPayment}
                      onChange={(e: any) => handleChange(e)}
                    >
                      <option value="" >
                        PAYMENT TYPE
                      </option>
                      <option value="CASH">
                        CASH
                      </option>
                    </select>
                    {vendorErrors.paymentType && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {vendorErrors.paymentType}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {!id && selectedPayment === "CASH" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      CASH AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        name="cashAmount"
                        value={CashAmount}
                        readOnly
                        autoComplete="nope"
                        onChange={(e: any) => handleChange(e)}
                        placeholder="0.00"
                        type="Number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                }
              >
                <div
                  className={
                    "w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
                  }
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "w-full sm:w-1/2  text-right sm:pr-9 flex flex-row sm:block items-center"
                    }
                  >
                    BALANCE AMOUNT
                  </div>
                  {selectedPayment === "CASH" && (
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2  text-right sm:pr-9  flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        value={balanceAmount}
                        // name="balanceAmt"
                        autoComplete="nope"
                        placeholder="0.00"
                        type=""
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                    </div>
                  )}


                </div>
              </div>

            </div>
          </form>
        </div>
        <div className="sticky top-[12px] right-0 w-full lg:w-2/12 pl-2 pr-4 sm:pr-2 mt-3 mx-4 md:mx-0 self-start xl:pb-20 sm:pb-40 xs:pb-40">
          <div style={{ fontFamily: "poppins", fontSize: "14px" }}>
            <button
              type="button"
              className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
              style={{ backgroundColor: "#008065" }}
              onClick={openChoosePreview}
            >
              Pre View
            </button>
          </div>
          <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className="mt-2"
          >
            <button
              className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
              style={{ backgroundColor: "#008065" }}
              onClick={handlePrint}
            >
              Print
            </button>
          </div>
          <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className=" w-full text-right mt-2 "
          >
            <button
              // size="sm"
              type="submit"
              className="rounded-xl font-bold text-white  flex flex-row-reverse flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
              style={{ backgroundColor: "#008065" }}
              onClick={(e: any) => handleSubmit(e)}
            >
              <svg
                className="pr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 20 20"
                fill="white"
              >
                <mask
                  id="mask0_278_27"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <rect width="20" height="20" fill="white" />
                </mask>
                <g mask="url(#mask0_278_27)">
                  <path
                    d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
                    fill="white"
                  />
                </g>
              </svg>
              {/* {params.id !== "new" ? "Update" : "Download"} */}
              Download
            </button>
          </div>
          <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className=" w-full text-right mt-2 "
          >
            <button
              type="button"
              className="rounded-xl font-bold text-white  flex flex-row-reverse  items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full whitespace-nowrap"
              style={{ backgroundColor: "#008065" }}
              onClick={(e: any) => handleSectionOpen(e)}
            >
              <svg
                className="pl-2"
                width="30"
                height="30"
                viewBox="0 0 20 20"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_278_36"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="23"
                  height="23"
                >
                  <rect
                    y="12.0752"
                    width="16"
                    height="16"
                    transform="rotate(-49 0 12.0752)"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_278_36)">
                  <path
                    d="M11.3749 19.3134L3.32462 12.3154L15.6598 6.25476L11.3749 19.3134ZM10.7402 16.995L13.4073 8.84593L5.70878 12.6213L7.46977 14.1521L10.8487 11.7893L8.97919 15.4642L10.7402 16.995Z"
                    fill="white"
                  />
                </g>
              </svg>
              Send Email
            </button>
          </div>

          {/* <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className=" w-full  mt-2 sm:mb-auto mb-16"
          >
            <ColorScheme
              clientList={vendorList}
              handleColorChange={handleColorChange}
              setColorPicker={setColorPicker}
              ColourEmpty={ColourEmpty}
            />
          </div> */}
        </div>
      </div>

      {openmail && (
        <Transition.Root show={openmail} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpenMail}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <SendEmailModel
                      closeModal={closeModal}
                      handleChange={handleChange}
                      formData={formData}
                      setFormData={setFormData}
                      handleSendEmail={handleSendEmail}
                      sendEmailErrors={sendEmailErrors}
                      errorEmail={errorEmail}
                      setErrorEmail={setErrorEmail}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {confirmDownload && (
        <ConfirmationDownload
          onDownload={downloadInvoice}
          onSaveDownload={saveAndDownloadInvoice}
          onCancel={handleCancel}
        />
      )}
      {showConfirmation && (
        <PaymentUpdateModel
          message="Are you sure you want to proceed?"
          onCancel={handleCancelForPaymentType}
        />
      )}
      <Transition.Root show={viewOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpenView}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:mx-8 lg:w-10/12 md:w-11/12 ">
                  {(userdata?.taxType == "Bill Wise" && formData.billTaxType == "Bill Wise") ? (
                    <Preview
                      Invoice={formData}
                      componentRef={componentRef}
                      closeModal={closeModal}
                      selectedFont={selectedFont}
                    />
                  ) : (
                    <>
                      <PreviewNewProductwise
                        Invoice={formData}
                        componentRef={componentRef}
                        closeModal={closeModal}
                        selectedFont={selectedFont}
                      />
                    </>
                  )}


                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

    </>

  );
}

export default PurchaseReturn;
